import styles from './index.module.scss';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
	footerZindex: {
		['@media (max-width:768px)']: {
			zIndex: 'inherit',
		},
	},
}));

export function Footer({ ...props }) {
	const classes = useStyles();
	return (
		<Box
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			className={clsx([styles['common-footer']], classes.footerZindex)}
			component="footer"
			{...props}
		>
			<div className={clsx([styles['common-footer--copyright']])}>
				&copy; Copyright {new Date().getFullYear()}, All Rights Reserved.
			</div>
			<div className={clsx([styles['common-footer--footer-links']])}>
				<Box mr={1} display="inline-flex">
					<Link target="_blank" to="/terms">
						Terms of use
					</Link>
				</Box>
				<Box aria-hidden mr={1} display="inline-flex">
					|{' '}
				</Box>
				<Box mr={1} display="inline-flex">
					<Link target="_blank" to="/privacy_policy">
						Privacy policy
					</Link>
				</Box>
			</div>
		</Box>
	);
}
