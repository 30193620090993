import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import saga from './saga';

export const initialState = {
	loading: false,
	error: null,
	scanDetail: {
		totalPages: 0,
		totalItems: 0,
		currentPage: 1,
		perPage: 0,
		scans: [],
	},
	currScan: {},
	currSelected: null,
};

const slice = createSlice({
	name: 'userFocusOrders',
	initialState,
	reducers: {
		loadTemplate(state, action) {},
		updateScanDetails(state, action) {
			const { payload } = action;
			const { totalPages, totalItems, currentPage, perPage, items } = payload;
			for (let index = 0; index < items?.length; index++)
				state.scanDetail.scans[(currentPage - 1) * perPage + index] =
					items[index];

			state.scanDetail.totalPages = totalPages;
			state.scanDetail.totalItems = totalItems;
			state.scanDetail.currentPage = Math.min(currentPage, totalPages);
			state.scanDetail.perPage = perPage;
		},
		updateCurrScan(state, action) {
			const { payload } = action;

			state.currScan = payload;
		},
		setError(state, action) {
			const { payload } = action;
			state.error = payload;
			state.loading = false;
		},
		setLoading(state, action) {
			const { payload } = action;
			state.loading = payload;
		},
		setCurrSelectedTabSequence(state, action) {
			const { payload } = action;

			state.currSelected = payload;
		},
		updateFocusOrderOnDelete(state) {
			state.scanDetail.scans = [];
		},
		resetCurrScanState(state) {
			state.currScan = {};
		},
		resetState(state) {
			state.currScan = {};
			state.error = null;
			state.loading = false;
			state.scanDetail = {
				totalPages: 0,
				totalItems: 0,
				currentPage: 1,
				perPage: 0,
				scans: [],
			};
			state.currSelected = null;
		},
		fetchFocusOrderDetailAction() {},
		deleteFocusOrderByIdAction() {},
		fetchFocusOrderByIdAction() {},
	},
});

const { actions, reducer, name } = slice;

export const {
	loadTemplate,
	fetchFocusOrderDetailAction,
	updateScanDetails,
	setError,
	setLoading,
	fetchFocusOrderByIdAction,
	updateCurrScan,
	setCurrSelectedTabSequence,
	updateFocusOrderOnDelete,
	deleteFocusOrderByIdAction,
	resetState,
	resetCurrScanState,
} = actions;

export const useFocusOrderScanSlice = () => {
	useInjectReducer({ key: name, reducer });
	useInjectSaga({ key: name, saga });
	return {
		...actions,
	};
};
