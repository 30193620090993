import { Box, Icon, Typography, Collapse } from '@mui/material';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import {
	fetchTabSequenceByIdAction,
	selectCurrTabSequence,
	selectTabSequenceMap,
	updateCurrentTabSequence,
} from 'reducers/shared';
import { TabListItem } from './ListItem';

const useStyles = makeStyles((theme) => ({
	annotationSummary: {
		// height: '568px',
		overflowY: 'auto',
		'& ul': {
			listStyle: 'none',
			margin: '0px',
			paddingInlineStart: '0px',
			paddingTop: 0,
			'& li': {
				padding: '15px 20px',
				borderBottom: '1px dotted #cccccc',
				cursor: 'pointer',
				fontSize: '14px',
			},
			'& li:nth-child(1)': {
				borderTop: '1px dotted #cccccc',
			},
			'& li:hover': {
				background: '#f5f5f5',
			},
		},

		'& .hide': {
			display: 'none',
		},
	},
	annotationCount: {
		background: '#E8E8E9',
		width: 30,
		height: 30,
		borderRadius: '50%',
		color: '#333333',
		fontSize: 13,
		fontWeight: '600',
		marginLeft: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tabSequenceLabel: {
		display: 'block',
		'&:hover, &:focus': {
			'& > .tab_sequence__list__icon': {
				background: '#A85400',
				borderColor: 'transparent',
				'& > .tab_sequence__icon': {
					background: '#A85400',
					color: 'white',
				},
			},
			'& > .tab_sequence__semantic__item': {
				color: '#A85400',
				fontWeight: '500',
			},
			'& > .tab_sequence__count': {
				background: 'white',
				color: '#A85400',
				fontWeight: '700',
				border: '1px solid #A85400',
			},
			outline: '2px solid #0077c8 !important',
			outlineOffset: '-3px',
			boxShadow: 'none !important',
		},
		padding: '8px 16px !important',
		cursor: 'pointer',
	},
	listIcon: {
		padding: 5,
		borderRadius: '50%',
		border: `1px solid ${theme.palette.border.black}`,
		marginRight: 10,
		display: 'flex',
		alignItems: 'center',
	},
	listItemName: {
		fontSize: 16,
	},
	icon: {},
	activeListIcon: {
		background: '#A85400',
		borderColor: 'transparent',
	},
	activeLabel: {
		background: 'rgba(255, 159, 64, 0.1)',
	},
	activeIcon: {
		background: '#A85400',
		color: 'white',
	},
	activeAnnotationCount: {
		background: 'white',
		color: '#A85400',
		fontWeight: '700',
		border: '1px solid #A85400',
	},
	activeListItemName: {
		color: '#A85400',
		fontWeight: '500',
	},
	listContainer: {
		display: 'flex',
		alignItems: 'center',
		'&:hover, &:focus': {
			'& > .tab_sequence__list__icon': {
				background: '#A85400',
				borderColor: 'transparent',

				'& > .tab_sequence__icon': {
					background: '#A85400',
					color: 'white',
				},
			},
			'& > .tab_sequence__semantic__item': {
				color: '#A85400',
				fontWeight: '500',
			},
			'& > .tab_sequence__count': {
				background: 'white',
				color: '#A85400',
				fontWeight: '700',
				border: '1px solid #A85400',
			},
		},
	},
	expandIcon: {
		marginLeft: '27%',
		['@media (max-width:1024px)']: {
			marginLeft: '66%',
		},
		['@media (max-width:600px)']: {
			marginLeft: '35%',
		},
		['@media (max-width:375px)']: {
			marginLeft: '25%',
		},
		['@media (max-width:320px)']: {
			marginLeft: '9%',
		},
	},
}));

export function TabSequence({ name, id, count }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const currentTabSequenceAnnotation = useSelector(selectCurrTabSequence);
	const tabSequenceCache = useSelector(selectTabSequenceMap);

	const handleSemanticItemClick = (id, event) => {
		if (!tabSequenceCache[id]) {
			const payload = {
				id,
			};
			dispatch(fetchTabSequenceByIdAction(payload));
		} else if (
			currentTabSequenceAnnotation &&
			currentTabSequenceAnnotation.id === id
		) {
			dispatch(updateCurrentTabSequence(null));
		} else {
			dispatch(updateCurrentTabSequence(tabSequenceCache[id]));
		}
	};

	const handleKeyDownOnOption = (kbEvent) => {
		const { currentTarget } = kbEvent;
		if (kbEvent.code === 'Enter' || kbEvent.code === 'Space') {
			currentTarget?.click();
			currentTarget?.focus();
		}
	};

	return (
		<Box>
			<Box role="heading" aria-level="3">
				<Box
					role="button"
					tabIndex={0}
					className={clsx([
						currentTabSequenceAnnotation?.id === id && classes.activeLabel,
						classes.tabSequenceLabel,
					])}
					aria-expanded={currentTabSequenceAnnotation?.id === id}
					onClick={(event) =>
						handleSemanticItemClick(encodeURIComponent(id), event)
					}
					onKeyDown={handleKeyDownOnOption}
					aria-controls={id}
				>
					<Box className={clsx([classes.listContainer])}>
						<Box
							className={clsx([
								classes.listIcon,
								currentTabSequenceAnnotation?.id === id &&
									classes.activeListIcon,
								'tab_sequence__list__icon',
								'collapsedListIcon',
							])}
						>
							<Icon
								className={clsx([
									currentTabSequenceAnnotation?.id === id && classes.activeIcon,
									classes.icon,
									'tab_sequence__icon',
									'collapsedIcon',
								])}
							>
								<KeyboardTabIcon />
							</Icon>
						</Box>
						<Typography
							component="span"
							variant="span"
							className={clsx([
								currentTabSequenceAnnotation?.id === id &&
									classes.activeListItemName,
								classes.listItemName,
								'tab_sequence__semantic__item',
								'collapsedListItemName',
							])}
						>
							{name}
						</Typography>
						<Typography
							component="div"
							className={clsx([
								classes.annotationCount,
								currentTabSequenceAnnotation?.id === id &&
									classes.activeAnnotationCount,
								'tab_sequence__count',
								'collapsedCount',
							])}
							aria-label={`${
								count > 1
									? `${count} annotation items`
									: `${count} annotation item`
							}`}
						>
							{count}
						</Typography>
						<Icon className={clsx([classes.icon, classes.expandIcon])}>
							{currentTabSequenceAnnotation?.id === id ? (
								<ExpandLessOutlinedIcon />
							) : (
								<ExpandMoreOutlinedIcon />
							)}
						</Icon>
					</Box>
				</Box>
			</Box>
			<Collapse
				role="region"
				id={id}
				in={currentTabSequenceAnnotation?.id === id}
				timeout="auto"
				aria-hidden={currentTabSequenceAnnotation?.id !== id ? 'true' : 'false'}
				className={clsx([currentTabSequenceAnnotation?.id !== id && 'hide'])}
			>
				<TabListItem id={id} />
			</Collapse>
		</Box>
	);
}
