import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { DEQUE_LOGO } from 'assets/images/common';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthMessageContainer } from 'components';

const useStyles = makeStyles((theme) => ({
	rootContainer: {},
	logoContainer: {
		display: 'flex',
		marginRight: 2,
	},
	logoLink: {
		margin: '10px 0 0 0',
	},
	logo: {
		maxHeight: 50,
		cursor: 'pointer',
	},
	rightContainer: {
		backgroundColor: theme.palette.white,
		paddingRight: 18,
		minHeight: 54,
		paddingTop: 5,
	},
}));

export const AuthHeader = ({ className, ...props }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const handleLogoClick = () => {
		navigate('/login');
	};

	const handleFocus = (event) => {
		let targetElem = event.target;
		targetElem.setAttribute('style', "border-bottom:'2px solid white'");
	};

	const onBlur = (event) => {
		let targetElem = event.target;
		targetElem.removeAttribute('style');
	};

	const headerAction =
		pathname === '/login' ? (
			<AuthMessageContainer hrefLink="/register" />
		) : (
			<AuthMessageContainer
				hrefLink="/login"
				title="Already have an account?"
				btnText="Login"
			/>
		);

	return (
		<AppBar
			{...props}
			position="static"
			className={clsx(classes.rootContainer, className)}
		>
			<Grid container columnSpacing={2}>
				<Grid
					flexDirection="column"
					justifyContent="space-between"
					className={clsx([classes.leftContainer])}
					item
					md={6}
				>
					<Container maxWidth={false}>
						<Toolbar disableGutters>
							<Typography
								variant="h6"
								noWrap
								component="div"
								className={classes.logoContainer}
							>
								<a
									href="/login"
									className={classes.logoLink}
									onFocus={handleFocus}
									onBlur={onBlur}
								>
									<img
										onClick={handleLogoClick}
										className={classes.logo}
										src={DEQUE_LOGO}
										alt="Deque Logo"
									/>
								</a>
							</Typography>
						</Toolbar>
					</Container>
				</Grid>
				<Grid
					container
					alignItems="flex-end"
					flexDirection="column"
					justifyContent="space-between"
					className={clsx([classes.rightContainer])}
					item
					md={6}
					xs={12}
				>
					{headerAction}
				</Grid>
			</Grid>
		</AppBar>
	);
};
