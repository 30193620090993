export const INVALID_FORM_FIELD_SUBMIT_MESSAGE =
	'Submitted form field are not valid';

export const AUTH_ACTIONS = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	REGISTER: 'REGISTER',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	RESET_PASSWORD: 'RESET_PASSWORD',
	SESSION_TIMED_OUT: 'SESSION_TIMED_OUT',
};

export const AUTH_ACTIONS_MESSAGES = {
	[AUTH_ACTIONS.LOGIN]: 'Login',
	[AUTH_ACTIONS.LOGOUT]: 'Logout',
	[AUTH_ACTIONS.REGISTER]: 'Register',
	[AUTH_ACTIONS.FORGOT_PASSWORD]: 'Forgot password',
	[AUTH_ACTIONS.RESET_PASSWORD]: 'Reset password',
	[AUTH_ACTIONS.SESSION_TIMED_OUT]:
		'No active session found, please login to continue',
};
