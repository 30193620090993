import { Box, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Interweave } from 'interweave';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SEMANTIC_GROUP_ICONS } from 'constants';
import {
	useSharedSlice,
	selectLabelDetailDescription,
	selectLabelDetailTitle,
	selectLabelMap,
} from 'reducers/shared';

const useStyles = makeStyles((theme) => ({
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#A85400',
		width: 50,
		height: 50,
		borderRadius: '50%',
		marginRight: 5,
	},
	icon: {
		fontSize: '2rem',
		color: 'white',
	},
	titleContainer: {
		margin: '0 20px',
	},
}));

export function GroupLabelRenderer({
	label,
	headingConfig = {},
	headingLevel = 1,
	isIconVisible = false,
}) {
	const { updateCurrentLabelDetail, fetchSemanticByLabelAction } =
		useSharedSlice();
	const classes = useStyles();

	const dispatch = useDispatch();

	const labelCache = useSelector(selectLabelMap);
	const labelTitle = useSelector(selectLabelDetailTitle);
	const labelDescription = useSelector(selectLabelDetailDescription);

	const decodedLabel = encodeURIComponent(label);
	useEffect(() => {
		if (!decodedLabel) return;

		if (!labelCache[decodedLabel]) {
			const payload = {
				label: decodedLabel,
			};
			dispatch(fetchSemanticByLabelAction(payload));
		} else dispatch(updateCurrentLabelDetail(labelCache[decodedLabel]));
	}, [labelCache[decodedLabel]]);

	return decodedLabel ? (
		<Box>
			<Grid container>
				<Grid container alignItems="center" my={3}>
					{isIconVisible && (
						<Grid item className={clsx([classes.iconContainer])}>
							<Icon className={classes.icon}>
								{SEMANTIC_GROUP_ICONS[labelTitle] ||
									SEMANTIC_GROUP_ICONS['default']}
							</Icon>
						</Grid>
					)}
					<Grid item>
						<Typography
							component={`h${headingLevel}`}
							variant={`h${headingLevel}`}
							{...headingConfig}
						>
							{labelTitle}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} className={clsx([classes.descContainer])}>
					{/* <Grid item xs={12} > */}
					<Interweave content={labelDescription} />
				</Grid>
			</Grid>
		</Box>
	) : null;
}
