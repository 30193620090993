import styles from './index.module.scss';

export const LoadingIndicator = (props) => (
	<svg
		className={styles['loading__container']}
		viewBox="-24 -24 48 48"
		small={props.small}
	>
		<circle
			className={styles['circle']}
			cx="0"
			cy="0"
			r="20"
			fill="none"
			strokeWidth="4"
		></circle>
	</svg>
);
