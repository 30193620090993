import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state) => state.auth || initialState;

export const selectIsSessionActive = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.isSessionActive,
);

export const selectAuthAction = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.authAction,
);

export const selectUserName = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.userDetail?.fullname,
);

export const selectResetFormField = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.resetFormField,
);

export const selectAuthLoading = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.loading,
);

export const selectAuthError = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.error,
);
