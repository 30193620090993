import { colors } from '@mui/material';

const defaultConfig = {
	primary: {
		main: '#003349',
	},
	secondary: {
		main: '#0077C8',
	},
	error: {
		main: '#DF0A0A',
		light: '#FFF4F4',
	},
	navbar: {
		profileIcon: {
			background: '#ffffff',
			color: '#3374C1',
		},
	},
	text: {
		primary: '#1E1E1E',
		dark: '#0077C8',
		light: '#0077C8',
		lighter: '#E6F2FA',
		lightGrey: '#666666',
		black: '#000000',
		// lighterGrey: '#8A8A8A',
		lighterGrey: '#666666',
		error: 'DF0A0A',
		secondary: '#333333',
	},
	border: {
		grey: '#E6E7E9',
		black: '#333333',
	},
	sidebar: {
		hover: '#E0E0E0',
		focus: '#B2D6EE',
		active: '#E5F1F9',
		border: '#CCCCCC',
		borderActive: '#333333',
	},
	divider: colors.grey[200],
	white: '#ffffff',
	issueDivider: '#E8E8E9',
	table: {
		headerBg: '#F8F8FA',
	},
};

export default defaultConfig;
