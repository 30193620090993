import { Box, styled } from '@mui/material';
import { LoadingIndicator } from 'common/LoadingIndicator';
import { lazyLoad } from 'utils/loadable';

const StyledBox = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '70vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const TermsOfUsePage = lazyLoad(
	() => import('./TermsOfUse'),
	(module) => module.TermsOfUsePage,
	{
		fallback: (
			<StyledBox>
				<LoadingIndicator />
			</StyledBox>
		),
	},
);
