import { IconButton, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { PER_PAGE } from '..';
import { selectCurrentPage } from 'reducers/focus-order/selector';
import { useFocusOrderScanSlice } from 'reducers/focus-order';

export default function ReportDelete({ name, id }) {
	const useStyles = makeStyles((theme) => ({
		iconLink: {
			color: `${theme.palette.primary.dark}`,
			cursor: 'pointer',
		},
	}));
	const classes = useStyles();

	const dispatch = useDispatch();
	const currentPage = useSelector(selectCurrentPage);
	const { deleteFocusOrderByIdAction } = useFocusOrderScanSlice();

	const deleteAnnotation = (id) => {
		dispatch(
			deleteFocusOrderByIdAction({
				id,
				perPage: PER_PAGE,
				page: currentPage,
			}),
		);
	};

	return (
		<IconButton
			className={clsx([classes.iconLink])}
			onClick={() => deleteAnnotation(id)}
			aria-label={'delete report, ' + name}
		>
			<Icon>delete_outline</Icon>
		</IconButton>
	);
}
