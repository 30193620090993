import './App.scss';
import { useRoutes } from 'react-router-dom';
import { Container } from '@mui/material';
import { ROUTES } from './routes';
import { NotificationContainer } from 'common';
import { usePageRouterHandler } from 'hooks';
import { useEffect } from 'react';

function App() {
	const routes = useRoutes(ROUTES);

	usePageRouterHandler(ROUTES);

	// scroll to top after refresh
	useEffect(() => {
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		};
	}, []);

	return (
		<Container maxWidth={false} disableGutters>
			<NotificationContainer />
			{routes}
		</Container>
	);
}

export default App;
