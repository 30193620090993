import { delay, put, takeLatest, call } from 'redux-saga/effects';

import {
	fetchFocusOrderDetailAction,
	loadTemplate,
	updateScanDetails,
	setLoading,
	setError,
	fetchFocusOrderByIdAction,
	updateCurrScan,
	updateFocusOrderOnDelete,
	resetCurrScanState,
	deleteFocusOrderByIdAction,
} from '.';
import { AxedApi } from 'services';
import {
	AUTH_ACTIONS_MESSAGES,
	AUTH_ACTIONS,
	TOAST_MESSAGE_TYPE,
} from 'constants';
import { updateNotificationMessage } from 'reducers/shared/saga';

function* updateLoadingState(state = false) {
	yield put(setLoading(state));
}

function* updateErrorState(payload) {
	yield put(setError(payload));
}

export function* fetchFocusOrderDetail(action) {
	const { payload = {} } = action;

	const REQUEST_URL = `/user/focus-orders`;

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.get, REQUEST_URL, { params: payload });

		const { data } = response;
		const { data: scans } = data;

		yield put(updateScanDetails(scans));
	} catch (err) {
		if (!err?.data) {
			return;
		}

		const { message } = err.data;
		const { status } = err;

		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* fetchFocusOrderById(action) {
	const id = action.payload;

	const REQUEST_URL = `/focus-order/${id}`;

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.get, REQUEST_URL);

		const { data } = response;

		yield put(updateCurrScan(data));
	} catch (err) {
		if (!err?.data) {
			return;
		}

		const { message } = err.data;
		const { status } = err;
		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* deleteFocusOrderById(action) {
	const { id, ...focusOrderPayload } = action.payload;

	const REQUEST_URL = `/focus-order/${id}`;

	try {
		const response = yield call(AxedApi.delete, REQUEST_URL);

		const { data } = response;
		const { message } = data;

		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield put(updateFocusOrderOnDelete(id));
		yield put(fetchFocusOrderDetailAction(focusOrderPayload));
		yield call(updateNotificationMessage, notifyPayload);
		yield call(resetCurrScanState());
	} catch (err) {
		if (!err?.data) {
			return;
		}

		const { message } = err.data;
		const { status } = err;
		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

// Root Saga manages watcher lifecycle
export default function* watcherSaga() {
	yield takeLatest(fetchFocusOrderDetailAction.type, fetchFocusOrderDetail);
	yield takeLatest(fetchFocusOrderByIdAction.type, fetchFocusOrderById);
	yield takeLatest(deleteFocusOrderByIdAction.type, deleteFocusOrderById);
}
