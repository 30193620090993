import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { DEQUE_LOGO } from 'assets/images/common';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	rootContainer: {},
	logoContainer: {
		display: 'flex',
		marginRight: 2,
	},
	logoLink: {
		margin: '3px 0 0 0',
	},
	logo: {
		maxHeight: 50,
		cursor: 'pointer',
	},
	rightContainer: {
		backgroundColor: theme.palette.white,
		paddingRight: 18,
		minHeight: 54,
		paddingTop: 15,
	},
	center: {
		margin: '0 auto',
	},
}));

export const PublicHeader = ({ className, ...props }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate('/');
	};

	const handleFocus = (event) => {
		let targetElem = event.target;
		targetElem.setAttribute('style', "border-bottom:'2px solid white'");
	};

	const onBlur = (event) => {
		let targetElem = event.target;
		targetElem.removeAttribute('style');
	};

	return (
		<AppBar
			{...props}
			position="static"
			className={clsx(classes.rootContainer, className)}
		>
			<Grid container columnSpacing={2}>
				<Grid className={classes.center}>
					<Container>
						<Toolbar disableGutters>
							<Grid item xs={12}>
								<Typography
									variant="h6"
									noWrap
									component="div"
									sx={{ flexGrow: 1 }}
								>
									Axe For Designers
								</Typography>
							</Grid>
						</Toolbar>
					</Container>
				</Grid>
			</Grid>
		</AppBar>
	);
};
