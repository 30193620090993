import { Box, Grid, Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectTabSequences } from 'reducers/focus-order/selector';
import { TabSequence } from './components';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
		border: `3px solid ${theme.palette.border.grey}`,
		// padding: 20,
		'& h2': {
			margin: '30px 20px 30px',
			fontWeight: 550,
			fontSize: '2.0rem',
			lineHeight: '1.5rem',
		},
	},
}));

export function TabSequences() {
	const classes = useStyles();

	const tabSequences = useSelector(selectTabSequences);

	const renderTabSequences = () => {
		return (
			<Grid className={clsx([classes.annotationSummary])}>
				<Box
					aria-labelledby="view__tab_sequences"
					style={{ maxHeight: '845px', overflow: 'auto' }}
				>
					{tabSequences?.length > 0 ? (
						tabSequences.map(({ name, count, id }) => {
							return <TabSequence key={id} name={name} count={count} id={id} />;
						})
					) : (
						<ListItem> No Tab Sequences </ListItem>
					)}
				</Box>
			</Grid>
		);
	};

	return (
		<Grid item xs={12} lg={4} className={clsx([classes.cardContainer])}>
			<Typography component="h2" variant="h5" id="view__tab_sequences">
				Tab Sequences
			</Typography>
			{renderTabSequences()}
		</Grid>
	);
}
