import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

export function PageRouter({ children }) {
	return (
		<Fragment>
			<Outlet />
			{children}
		</Fragment>
	);
}
