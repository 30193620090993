import styles from './index.module.scss';
import clsx from 'clsx';
import { Icon } from '@mui/material';
import { useState } from 'react';
import { onPressEnter } from 'utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textField: {
		minWidth: 'inherit',
	},
}));

export function TextField({
	className,
	label,
	children,
	hasError = false,
	...props
}) {
	const classes = useStyles();
	const [inputType, setInputType] = useState(props.type || 'text');

	const handleEyeClick = (event) => {
		setInputType((prev) => {
			let target = event.target;
			if (prev === props.type) {
				target?.setAttribute('aria-label', 'hide password');
				return 'text';
			} else {
				target?.setAttribute('aria-label', 'show password');
			}
			return props.type;
		});
	};

	return (
		<div className={clsx([styles['text-field-container'], className])}>
			<label
				htmlFor={`text__field__${label}`}
				className={clsx([styles['text-field-label']])}
			>
				{label}
				<span className={clsx([styles['required-indicator']])}>
					{props.required ? '*' : ''}
				</span>
			</label>
			<div className={clsx([styles['text-field-item-container']])}>
				<input
					className={clsx(
						[styles['text-field-item'], hasError && styles['error-field']],
						classes.textField,
					)}
					{...props}
					type={inputType}
					id={`text__field__${label}`}
					data-label={label}
				/>
				{props.type === 'password' && (
					<Icon
						aria-label="show password"
						onClick={handleEyeClick}
						className={clsx([styles['eye-icon']])}
						role="button"
						tabIndex="0"
						aria-hidden={false}
						onKeyDown={onPressEnter}
					>
						{inputType !== 'password' ? 'visibility' : 'visibility_off'}
					</Icon>
				)}
			</div>
			{children}
		</div>
	);
}
