import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	messageContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	messageButton: {
		backgroundColor: theme.palette.white,
		color: theme.palette.text.light,
		fontSize: '0.9rem',
	},
	messageDesc: {
		margin: '0 10px',
	},
}));

export function AuthMessageContainer({
	hrefLink = '/register',
	title = `Don't have an account?`,
	btnText = 'Register',
	className,
}) {
	const classes = useStyles();

	const navigate = useNavigate();

	const handleBtnClick = () => {
		navigate(hrefLink);
	};

	return (
		<Grid item xs={12} className={clsx([classes.messageContainer, className])}>
			<Box>
				<Typography
					component="span"
					variant="span"
					className={clsx([classes.messageDesc])}
					color="text.lightGrey"
				>
					{title}
				</Typography>
			</Box>
			<Button
				size="small"
				onClick={handleBtnClick}
				variant="outlined"
				className={clsx([classes.messageButton])}
				color="secondary"
			>
				{btnText}
			</Button>
		</Grid>
	);
}
