import { createDraftSafeSelector } from '@reduxjs/toolkit';

const { initialState } = require('.');

const selectDomain = (state) => state.userFocusOrders || initialState;

export const selectCurrScan = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.currScan,
);

export const selectTabSequences = createDraftSafeSelector(
	[selectCurrScan],
	(state) => state?.data?.tabSequences,
);

export const selectCurrSelectedTabSequence = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.currSelected,
);

export const selectCurrScanName = createDraftSafeSelector(
	[selectCurrScan],
	(state) => state?.data?.name,
);

export const selectCurrScanImage = createDraftSafeSelector(
	[selectCurrScan],
	(state) => state?.data?.image,
);

export const selectCurrScanType = createDraftSafeSelector(
	[selectCurrScan],
	(state) => state?.selectedFrameType,
);

export const selectCurrScanCreatedAt = createDraftSafeSelector(
	[selectCurrScan],
	(state) => state?.data?.createdAt,
);

export const selectTotalPages = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.scanDetail?.totalPages,
);

export const selectCurrentPage = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.scanDetail?.currentPage,
);

export const selectTotalScans = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.scanDetail?.totalItems,
);

export const selectScans = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.scanDetail?.scans,
);

export const selectVisitedPages = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.visitedPages,
);

export const selectScanLoading = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.loading,
);

export const selectScanError = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.error,
);
