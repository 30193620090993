import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { watcherSaga } from './saga';

export const initialState = {
	loading: false,
	error: null,
	userDetail: {},
	isSessionActive: false,
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserDetail(state, action) {
			const { payload } = action;
			state.userDetail = payload;
		},
		setError(state, action) {
			const { payload } = action;
			state.error = payload;
			state.loading = false;
		},
		setLoading(state, action) {
			const { payload } = action;
			state.loading = payload;
		},
		resetState(state) {
			state.userDetail = {};
		},
		fetchCurrentUserDetail() {},
	},
});

const { actions, reducer, name } = slice;

export const {
	setError,
	setLoading,
	updateUserDetail,
	resetState,
	fetchCurrentUserDetail,
} = actions;

export const useUserSlice = () => {
	useInjectReducer({ key: name, reducer });
	useInjectSaga({ key: name, saga: watcherSaga });
	return {
		...actions,
	};
};
