import { Badge, Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme, color, bgColor) => ({
	container: {
		background: bgColor || theme.palette.error.light,
		border: `1px solid ${color || theme.palette.error.main}`,
		borderRadius: 10,
	},
	outlinedIcon: {
		fontSize: '3rem',
	},
	badgeContent: {
		color: color || theme.palette.error.main,
		position: 'absolute',
		bottom: 2,
		backgroundColor: 'white',
		borderRadius: '50%',
	},
	badgeContainer: {
		marginRight: 15,
	},
}));

export function Banner({
	icon,
	vertical = 'bottom',
	horizontal = 'right',
	overlap = 'circular',
	badgeContent,
	title,
	color,
	bgColor,
	children,
	...props
}) {
	const classes = useStyles(color, bgColor);
	const general = useTheme().general();

	const Icon = icon;
	const BadgeContent = badgeContent;

	return (
		<Box
			className={clsx([general.flexAlignItemsCenter, classes.container])}
			p={2}
			{...props}
		>
			{icon && (
				<Box className={clsx([classes.badgeContainer])}>
					<Badge
						overlap={overlap}
						anchorOrigin={{
							horizontal,
							vertical,
						}}
						badgeContent={
							<BadgeContent className={clsx([classes.badgeContent])} />
						}
					>
						<Icon
							className={clsx([classes.outlinedIcon])}
							color={color || 'error'}
						/>
					</Badge>
				</Box>
			)}
			<Box>
				<Typography fontSize="14px" fontWeight="bold" color={color || 'error'}>
					{title}
				</Typography>
				{children}
			</Box>
		</Box>
	);
}
