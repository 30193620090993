import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import {
	Container,
	Typography,
	Grid,
	useTheme,
	Icon,
	Button,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useFocusOrderScanSlice } from 'reducers/focus-order';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectCurrScanCreatedAt,
	selectCurrScanImage,
	selectCurrScanName,
} from 'reducers/focus-order/selector';
import { selectLabelMap } from 'reducers/shared';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { TabSequences } from 'components/ViewFocusOrderReportComponents/TabSequences';
import { PreviewSection } from 'components/ViewFocusOrderReportComponents/PreviewSection';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
			3,
		)} ${theme.spacing(0)}`,
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	cardContainer: {
		border: `1px solid ${theme.palette.border.grey}`,
		borderRadius: 8,
		margin: '25px 25px 25px 0',
	},
	cardParentContainer: {
		margin: '25px 0',
	},
	divider: {
		width: 2,
		height: 18,
		backgroundColor: theme.palette.text.lightGrey,
	},
	iconLink: {
		fontSize: '16px',
		paddingRight: '5px',
		position: 'relative',
		top: '1px',
	},
	anchorHolder: {
		display: 'inherit',
		alignItems: 'center',
		color: theme.palette.text.light,
	},
	exportBtn: {
		padding: '5px 20px 5px 20px',
		border: `1px solid ${theme.palette.text.light}`,
		borderRadius: '4px',
		textDecoration: 'none',
		color: theme.palette.text.light,
		fontSize: '15px',
		margin: '5px',
		textTransform: 'none',
		whiteSpace: 'nowrap',
	},
	auditText: {
		'& span': {
			['@media (maxn-width:600px)']: {
				fontSize: 'medium',
				whiteSpace: 'inherit',
			},
			['@media (max-width:400px)']: {
				fontSize: 'small',
				whiteSpace: 'inherit',
			},
		},
	},
}));

export default function ViewFocusOrder() {
	const classes = useStyles();
	const general = useTheme().general();
	const { id } = useParams();
	const dispatch = useDispatch();

	const { fetchFocusOrderByIdAction } = useFocusOrderScanSlice();

	useEffect(() => {
		dispatch(fetchFocusOrderByIdAction(id));
	}, [id]);

	const frameName = useSelector(selectCurrScanName);
	const reportDate = useSelector(selectCurrScanCreatedAt);

	const renderReportDate = (dateString) => {
		if (!dateString) {
			return '';
		}
		let date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true,
		});
	};

	return (
		<Container maxWidth={false} className={classes.root}>
			<Helmet>
				<title> Focus Order Report: Axe for Designers </title>
			</Helmet>
			<Grid container>
				<Grid container>
					<Grid
						item
						xs={12}
						className={clsx([
							general.flexJustifyContentSpaceBetween,
							general.my1,
						])}
					>
						<NavLink
							to="/focus-orders"
							className={clsx([classes.anchorHolder])}
						>
							<Icon className={clsx([classes.iconLink])}>arrow_back</Icon>
							<Typography component="p">Back</Typography>
						</NavLink>
					</Grid>
					<Grid container className={clsx([general.flexAlignItemsCenter])}>
						<Typography
							role="heading"
							aria-level={1}
							component="h1"
							variant="h4"
							sx={{ display: { xs: 'none', md: 'flex' } }}
						>
							{frameName}
						</Typography>
						<Typography
							role="heading"
							aria-level={1}
							variant="h6"
							noWrap
							sx={{ display: { xs: 'flex', md: 'none' } }}
						>
							{frameName}
						</Typography>
					</Grid>
					<Grid
						container
						className={clsx([general.flexAlignItemsCenter], classes.auditText)}
					>
						<Grid item xs={12} md={'auto'}>
							<Typography
								color="text.lightGrey"
								component="span"
								variant="span"
								whiteSpace="nowrap"
							>
								Reported At:{' '}
							</Typography>
							<Typography
								fontWeight="bold"
								whiteSpace="nowrap"
								className={clsx([general.mx05])}
								component="span"
								variant="span"
							>
								{renderReportDate(reportDate)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					justifyContent="space-between"
					className={classes.cardParentContainer}
					container
				>
					<PreviewSection className={general.my1} />
					<TabSequences className={general.my1} />
				</Grid>
			</Grid>
		</Container>
	);
}
