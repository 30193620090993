import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { configureAppStore } from 'store';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import {
	ThemeProvider,
	CssBaseline,
	StyledEngineProvider,
} from '@mui/material';
import theme from 'theme';

export const store = configureAppStore();

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<HelmetProvider>
				<React.StrictMode>
					<BrowserRouter>
						<StyledEngineProvider injectFirst>
							<CssBaseline />
							<App />
						</StyledEngineProvider>
					</BrowserRouter>
				</React.StrictMode>
			</HelmetProvider>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
