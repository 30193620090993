import * as React from 'react';
import {
	List,
	ListItem,
	Icon,
	ListItemText,
	Box,
	Typography,
	AccordionSummary,
	styled,
	useTheme,
	Accordion as MuiAccordion,
	AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

const DRAWER_CONFIG = [
	{
		icon: <SearchOutlinedIcon sx={{ marginTop: '10px' }} />,
		text: 'A11y Check',
	},
	{
		icon: <AssistantOutlinedIcon sx={{ marginTop: '10px' }} />,
		text: 'Annotations',
	},
	{
		icon: <KeyboardTabIcon sx={{ marginTop: '10px' }} />,
		text: 'Focus Order',
	},
	{
		icon: <DehazeOutlinedIcon />,
		text: 'Documentation',
		dropicon: <ExpandMoreOutlinedIcon />,
		upicon: <ExpandLessOutlinedIcon />,
		child: [
			{
				icon: <MenuBookOutlinedIcon />,
				text: 'How to Use',
				link: 'documentation/howtouse',
			},
			{
				icon: <InfoOutlinedIcon />,
				text: 'Product Info',
				link: 'documentation/productinfo',
			},
		],
	},
	{
		icon: <ArticleOutlinedIcon />,
		text: 'Use Cases',
		dropicon: <ExpandMoreOutlinedIcon />,
		upicon: <ExpandLessOutlinedIcon />,
		child: [
			{
				icon: '',
				text: 'Test a text node for color contrast issue',
				link: 'use-cases/test-a-text-node-for-color-contrast-issue',
			},
			{
				icon: '',
				text: 'Test a design for all color contrast issues',
				link: 'use-cases/test-a-design-for-all-color-contrast-issues',
			},
			{
				icon: '',
				text: 'Test a design for all touch target issues',
				link: 'use-cases/test-a-design-for-all-touch-target-issues',
			},
			{
				icon: '',
				text: 'Pass designer intent to developer',
				link: 'use-cases/pass-designer-intent-to-developer',
			},
			{
				icon: '',
				text: 'Pass designer intent to developer for form controls in just one click',
				link: 'use-cases/pass-designer-intent-to-developer-for-form-controls-in-just-one-click',
			},
		],
	},
	{
		icon: <ScienceOutlinedIcon sx={{ marginTop: '10px' }} />,
		text: 'Beta Features',
	},
	// {
	// 	icon: 'campaign',
	// 	text: 'Register for Beta Program',
	// },
];

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: 10,
	},
	iconMarginRight0: {
		marginRight: 0,
	},
	listItemContainer: {
		'&:not(:last-child)': {
			borderBottom: '1px dashed #CCCCCC',
		},
		display: 'flex',
		justifyContent: 'center',
	},
	listItem: {
		color: theme.palette.text.secondary,
		textDecoration: 'none',
		width: 220,
		padding: '1rem',
		borderRadius: 5,
		margin: 10,
		fontSize: 14,
		maxHeight: '48px',
	},
	listItemHoverFocus: {
		borderRadius: 5,
		maxHeight: '48px',
		'&:hover': {
			backgroundColor: theme.palette.sidebar.hover,
			borderRadius: 5,
			outline: 'none',
			boxShadow: 'none !important',
		},
		'&:focus': {
			backgroundColor: theme.palette.sidebar.hover,
			borderRadius: 5,
		},
	},
	listItemActive: {
		border: `1px solid ${theme.palette.sidebar.borderActive}`,
		backgroundColor: theme.palette.sidebar.hover,
		'& p': {
			fontWeight: 'bold',
			fontSize: 14,
			marginTop: 2,
		},
	},
	listItemText: {
		fontSize: 14,
		marginTop: 2,
		'& span': {
			fontSize: 14,
			marginTop: 2,
		},
	},
	listItemTextActive: {
		'& span': {
			fontWeight: 'bold',
			fontSize: 14,
			marginTop: 2,
		},
	},
	hoverno: {
		'&:hover': {
			backgroundColor: 'unset !important',
			color: 'unset !important',
			boxShadow: 'none !important',
		},
		'&:focus': {
			outline: 'none',
			boxShadow: 'none',
		},
	},
	subnav: {
		color: theme.palette.text.secondary,
		textDecoration: 'none',
		width: '100%',
		padding: '0.5rem',
		borderRadius: 5,
		margin: 10,
		boxShadow: 'none',
		fontSize: 14,
		'&:hover': {
			backgroundColor: theme.palette.sidebar.hover,
		},
	},
	useCasesNav: {
		color: theme.palette.text.secondary,
		textDecoration: 'none',
		width: '100%',
		padding: '0.5rem',
		borderRadius: 5,
		margin: 10,
		boxShadow: 'none',
		fontSize: 14,
		'&:hover': {
			backgroundColor: theme.palette.sidebar.hover,
		},
	},
	accordionBtnWrapper: {
		paddingLeft: 9,
		paddingRight: 10,

		'& a': {
			maxHeight: 'min-content',
		},
	},
	fontSize15: {
		'& span': {
			fontSize: 15,
			marginTop: '-2px',
		},
	},
}));

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: 'transparent',
	width: `100%`,
	textAlign: 'left',
	padding: '8px 16px 8px 0px',
	borderRadius: 5,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: '0',
}));

export function DrawerContent() {
	const classes = useStyles();
	const general = useTheme().general();
	const { pathname } = useLocation();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const ref = React.useRef(null);

	React.useEffect(() => {
		if (
			pathname == '/documentation/productinfo' ||
			pathname == '/documentation/howtouse'
		) {
			setExpanded('panel1');
		} else if (
			pathname == '/use-cases/test-a-text-node-for-color-contrast-issue' ||
			pathname == '/use-cases/test-a-design-for-all-color-contrast-issues' ||
			pathname == '/use-cases/test-a-design-for-all-touch-target-issues' ||
			pathname ==
				'/use-cases/pass-designer-intent-to-developer-for-form-controls-in-just-one-click' ||
			pathname == '/use-cases/pass-designer-intent-to-developer'
		) {
			setExpanded('panel2');
		} else {
			setExpanded(false);
		}
	}, [pathname]);

	const getActiveClassName = (text) => {
		switch (pathname) {
			case '/':
			case /^\/report(?:\/([^\/]+?))[\/]?$/i.test(pathname) ? pathname : null:
			case '/a11y-check':
				return 'a11y check' === text.toLowerCase();
			case /^\/annotation(?:\/([^\/]+?))[\/]?$/i.test(pathname)
				? pathname
				: null:
				return 'annotations' === text.toLowerCase();
			case /^\/focus-order(?:\/([^\/]+?))[\/]?$/i.test(pathname) ||
			/^\/focus-orders$/.test(pathname)
				? pathname
				: null:
				return 'focus order' === text.toLowerCase();
			default:
				return pathname === '/' + text?.replace(' ', '_')?.toLowerCase();
		}
	};

	const getNavigationPath = (text) => {
		switch (text.toLowerCase()) {
			case 'documentation':
				return '/documentation';
			case 'annotations':
				return '/annotations';
			case 'focus order':
				return '/focus-orders';
			case 'blogs':
				return '/blogs';
			case 'beta features':
				return '/beta_features';
			case 'how to use':
				return '/documentation/howtouse';
			case 'product info':
				return '/documentation/productinfo';
			case 'test a text node for color contrast issue':
				return '/use-cases/test-a-text-node-for-color-contrast-issue';
			case 'test a design for all color contrast issues':
				return '/use-cases/test-a-design-for-all-color-contrast-issues';
			case 'test a design for all touch target issues':
				return '/use-cases/test-a-design-for-all-touch-target-issues';
			case 'pass designer intent to developer for form controls in just one click':
				return '/use-cases/pass-designer-intent-to-developer-for-form-controls-in-just-one-click';
			case 'pass designer intent to developer':
				return '/use-cases/pass-designer-intent-to-developer';
			default:
				return '/a11y-check';
		}
	};

	return (
		<Box sx={{ overflow: 'auto' }}>
			<List
				sx={{
					paddingBottom: '81px',
				}}
			>
				{DRAWER_CONFIG.map((item) => (
					<ListItem
						className={clsx([classes.listItemContainer, classes.hoverno])}
						// button
						key={item.text}
						component="li"
					>
						{item.text == 'Documentation' ? (
							<Accordion
								expanded={expanded === 'panel1'}
								onChange={handleChange('panel1')}
								className={classes.accordionBtnWrapper}
							>
								<AccordionSummary className={classes.listItemHoverFocus}>
									<Icon className={classes.icon}>{item.icon}</Icon>
									<ListItemText
										className={classes.fontSize15}
										primary={item.text}
									/>
									{expanded === 'panel1' ? (
										<Icon
											className={clsx([classes.icon, classes.iconMarginRight0])}
										>
											{item.upicon}
										</Icon>
									) : (
										<Icon
											className={clsx([classes.icon, classes.iconMarginRight0])}
										>
											{item.dropicon}
										</Icon>
									)}
								</AccordionSummary>
								<AccordionDetails>
									{item.child.map((child) => (
										<NavLink
											to={getNavigationPath(child.text)}
											className={clsx([
												classes.listItemHoverFocus,
												classes.subnav,
												general.flexAlignItemsCenter,
												getActiveClassName(child.link) &&
													classes.listItemActive,
											])}
											key={child.text}
										>
											<Icon className={classes.icon}>{child.icon}</Icon>
											<Typography
												className={clsx([
													classes.listItemText,
													getActiveClassName(child.text) &&
														classes.listItemTextActive,
												])}
												primary={child.text}
											>
												{child.text}
											</Typography>
										</NavLink>
									))}
								</AccordionDetails>
							</Accordion>
						) : item.text == 'Use Cases' ? (
							<Accordion
								expanded={expanded === 'panel2'}
								onChange={handleChange('panel2')}
								className={classes.accordionBtnWrapper}
							>
								<AccordionSummary className={classes.listItemHoverFocus}>
									<Icon className={classes.icon}>{item.icon}</Icon>
									<ListItemText
										className={classes.fontSize15}
										primary={item.text}
									/>
									{expanded === 'panel2' ? (
										<Icon
											className={clsx([classes.icon, classes.iconMarginRight0])}
										>
											{item.upicon}
										</Icon>
									) : (
										<Icon
											className={clsx([classes.icon, classes.iconMarginRight0])}
										>
											{item.dropicon}
										</Icon>
									)}
								</AccordionSummary>
								<AccordionDetails>
									{item.child.map((child) => (
										<NavLink
											to={getNavigationPath(child.text)}
											className={clsx([
												classes.listItemHoverFocus,
												classes.useCasesNav,
												general.flexAlignItemsCenter,
												getActiveClassName(child.link) &&
													classes.listItemActive,
											])}
											key={child.text}
										>
											<Typography
												className={clsx([
													classes.listItemText,
													getActiveClassName(child.text) &&
														classes.listItemTextActive,
												])}
												primary={child.text}
											>
												{child.text}
											</Typography>
										</NavLink>
									))}
								</AccordionDetails>
							</Accordion>
						) : (
							<NavLink
								to={getNavigationPath(item.text)}
								className={clsx([
									classes.listItem,
									classes.listItemHoverFocus,
									general.flexAlignItemsCenter,
									getActiveClassName(item.text) && classes.listItemActive,
								])}
							>
								<Box className={classes.icon}>{item.icon}</Box>
								<ListItemText
									className={clsx([
										classes.listItemText,
										getActiveClassName(item.text) && classes.listItemTextActive,
									])}
									primary={item.text}
								/>
							</NavLink>
						)}
					</ListItem>
				))}
			</List>
		</Box>
	);
}
