import { Box, Drawer, Toolbar } from '@mui/material';
import { DrawerContent } from './DrawerContent';

const drawerWidth = 250;

export function Sidebar({ open, handler, ...props }) {
	return (
		<Box
			component="nav"
			sx={{ width: { md: drawerWidth, xs: 0 }, flexShrink: { sm: 0 } }}
			aria-label="Sidenav"
			{...props}
		>
			<Drawer
				variant="temporary"
				open={open}
				onClose={handler}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
					disableScrollLock: true,
				}}
				sx={{
					display: { xs: 'block', md: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
						paddingBottom: 'inherit',
					},
				}}
			>
				<Toolbar />
				<DrawerContent />
			</Drawer>
			<Drawer
				ModalProps={{
					disableScrollLock: true,
				}}
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					display: { xs: 'none', md: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
			>
				<Toolbar />
				<DrawerContent />
			</Drawer>
		</Box>
	);
}
