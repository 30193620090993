import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state) => state.user || initialState;

export const selectUserName = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.userDetail?.fullname,
);

export const selectUserLoading = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.loading,
);

export const selectUserError = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.error,
);

export const selectUser = createDraftSafeSelector(
	[selectDomain],
	(state) => state?.userDetail,
);
