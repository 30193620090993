import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AccountMenu from './AccountMenu';
import { makeStyles } from '@mui/styles';
import { DEQUE_LOGO } from 'assets/images/common';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	logoContainer: {
		marginRight: 2,
	},
	logoLink: {
		margin: '3px 0 0 0',
	},
	logo: {
		maxHeight: 54,
		cursor: 'pointer',
		verticalAlign: 'middle',
	},
	headerNoShadow: {
		boxShadow: 'none',
	},
	titleText: {
		fontSize: '1.4rem',
	},
}));

export const Header = ({ handler, noAccountMenu = false, ...props }) => {
	const classes = useStyles();

	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate('/');
	};

	const handleFocus = (event) => {
		let targetElem = event.target;
		targetElem.setAttribute('style', "border-bottom:'2px solid white'");
	};

	const onBlur = (event) => {
		let targetElem = event.target;
		targetElem.removeAttribute('style');
	};

	return (
		<AppBar
			position="fixed"
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			{...props}
			className={classes.headerNoShadow}
		>
			<Container maxWidth={false}>
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ display: { xs: 'none', md: 'flex' } }}
						className={classes.logoContainer}
					>
						<a
							href="/"
							className={classes.logoLink}
							onFocus={handleFocus}
							onBlur={onBlur}
						>
							<img
								onClick={handleLogoClick}
								className={classes.logo}
								src={DEQUE_LOGO}
								alt="Deque Logo"
							/>
						</a>
					</Typography>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="menu"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							aria-expanded={props.open}
							onClick={() => handler()}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
					</Box>
					<Typography
						variant="h6"
						noWrap
						textAlign="center"
						component="div"
						sx={{ flexGrow: 1 }}
						className={classes.titleText}
					>
						Axe For Designers
					</Typography>
					{!noAccountMenu && (
						<Box sx={{ flexGrow: 0 }}>
							<AccountMenu />
						</Box>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export * from './Header';
export * from './AuthHeader';
export * from './PublicHeader';
