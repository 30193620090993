import { Box, List, Typography, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectTabSequenceMap } from 'reducers/shared';

const DEFAULT_TAB_SEQUENCE_ANNOTATION = {
	id: '',
	name: '',
	annotationDropItems: [],
};

const useStyles = makeStyles((theme) => ({
	tabSequenceAnnotationIcon: {
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '10px',
		borderRadius: `0.10938rem`,
		border: `0.7px solid #FFF`,
		background: `#A323F5`,
		color: '#fff',
		fontWeight: 400,
	},
	innerListItem: {
		display: 'flex',
		border: 'none !important',
		padding: `13px 40px !important`,
		cursor: 'default !important',
	},
}));

export function TabListItem({ id }) {
	const classes = useStyles();

	const tabSequenceCache = useSelector(selectTabSequenceMap);
	const tabSequenceAnnotation = useRef(
		tabSequenceCache[id] || DEFAULT_TAB_SEQUENCE_ANNOTATION,
	);

	useEffect(() => {
		if (tabSequenceCache[id]?.id === tabSequenceAnnotation.current.id) return;

		Object.assign(
			tabSequenceAnnotation.current,
			tabSequenceCache[id] || DEFAULT_TAB_SEQUENCE_ANNOTATION,
		);
	}, [tabSequenceCache[id]]);

	return (
		<List
			className={clsx(classes.innerList)}
			aria-label={`${tabSequenceAnnotation.current.name} annotation Items`}
		>
			{tabSequenceAnnotation.current &&
			tabSequenceAnnotation.current.annotationDropItems?.length > 0 ? (
				tabSequenceAnnotation.current.annotationDropItems.map(
					(annotationItem) => {
						return (
							<ListItem
								className={clsx(classes.innerListItem)}
								key={annotationItem.id}
							>
								<Box className={clsx(classes.innerListItemBox)}>
									<span className={clsx(classes.tabSequenceAnnotationIcon)}>
										{' '}
										{annotationItem?.metadata?.sequenceNumber || -1}{' '}
									</span>
								</Box>
								<Typography component="span" variant="span">
									{annotationItem?.metadata?.nodeName || 'Node Name'}
								</Typography>
							</ListItem>
						);
					},
				)
			) : (
				<ListItem> No Items </ListItem>
			)}
		</List>
	);
}
