import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { watcherSaga } from './saga';

export const initialState = {
	shared: 'shared',
	toastMessage: {
		success: '',
		error: '',
		info: '',
		warn: '',
	},
	labelMap: {},
	currentLabelDetail: {
		label: '',
		description: '',
		id: '',
		type: '',
	},
	loading: false,
	error: null,
	tabSequenceMap: {},
	currentTabSequence: {},
};

const slice = createSlice({
	name: 'shared',
	initialState,
	reducers: {
		loadShared() {},
		setToastMessage(state, action) {
			const { payload } = action;

			const { message, type } = payload;

			state.toastMessage[type] = message;
		},
		setError(state, action) {
			const { payload } = action;
			state.error = payload;
			state.loading = false;
		},
		setLoading(state, action) {
			const { payload } = action;
			state.loading = payload;
		},
		updateCurrentLabelDetail(state, action) {
			const { payload } = action;

			state.currentLabelDetail = payload;
		},
		updateLabelMap(state, action) {
			const { payload } = action;

			state.labelMap = Object.assign(state.labelMap, payload);
		},
		updateTabSequenceMap(state, action) {
			const { payload } = action;

			state.tabSequenceMap = Object.assign(state.tabSequenceMap, payload);
		},
		updateCurrentTabSequence(state, action) {
			const { payload } = action;

			state.currentTabSequence = payload;
		},
		resetCurrentLabelDetail(state) {
			state.currentLabelDetail = {
				label: '',
				description: '',
				id: '',
				type: '',
			};
		},
		resetLabelMap(state) {
			state.labelMap = {};
		},
		resetTabSequenceMap(state) {
			state.tabSequenceMap = {};
		},
		fetchSemanticByLabelAction() {},
		fetchTabSequenceByIdAction() {},
		updateNotificationMessageAction() {},
	},
});

const { actions, reducer, name } = slice;

export const {
	loadShared,
	setToastMessage,
	setError,
	setLoading,
	updateCurrentLabelDetail,
	updateLabelMap,
	updateNotificationMessageAction,
	fetchSemanticByLabelAction,
	resetCurrentLabelDetail,
	resetLabelMap,
	updateTabSequenceMap,
	updateCurrentTabSequence,
	fetchTabSequenceByIdAction,
} = actions;

export const useSharedSlice = () => {
	useInjectReducer({ key: name, reducer });
	useInjectSaga({ key: name, saga: watcherSaga });
	return {
		...actions,
	};
};

export * from './saga';
export * from './selector';
