import { call, put, takeLatest } from 'redux-saga/effects';

import {
	fetchCurrentUserDetail,
	setError,
	setLoading,
	updateUserDetail,
} from '.';
import {
	AUTH_ACTIONS_MESSAGES,
	AUTH_ACTIONS,
	TOAST_MESSAGE_TYPE,
} from 'constants';
import { updateIsSessionActive } from 'reducers/auth';
import { updateNotificationMessage } from 'reducers/shared/saga';
import { AxedApi } from 'services';

function* updateLoadingState(state = false) {
	yield put(setLoading(state));
}

function* updateErrorState(payload) {
	yield put(setError(payload));
}

export function* fetchCurrentUser() {
	const REQUEST_URL = '/user/current';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.get, REQUEST_URL);
		const { data } = response;

		const { message, data: user } = data;

		yield put(updateUserDetail(user));
	} catch (error) {
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const { status } = error;
		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield put(updateIsSessionActive(false));
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

// Root Saga manages watcher lifecycle
export function* watcherSaga() {
	yield takeLatest(fetchCurrentUserDetail.type, fetchCurrentUser);
}
