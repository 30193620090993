import { delay, takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { AxedApi } from 'services';

import {
	loadShared,
	setToastMessage,
	setError,
	setLoading,
	updateCurrentLabelDetail,
	updateLabelMap,
	fetchSemanticByLabelAction,
	updateNotificationMessageAction,
	updateTabSequenceMap,
	updateCurrentTabSequence,
	fetchTabSequenceByIdAction,
} from '.';
import {
	TOAST_MESSAGE_TYPE,
	AUTH_ACTIONS,
	AUTH_ACTIONS_MESSAGES,
} from 'constants';

export function* fetchShared() {
	yield delay(500);
}

function* updateLoadingState(state = false) {
	yield put(setLoading(state));
}

function* updateErrorState(payload) {
	yield put(setError(payload));
}

export function* updateNotificationMessage(payload) {
	yield put(setToastMessage(payload));
	yield delay(500);
	const notifyPaylaod = {
		message: '',
		type: payload?.type,
	};
	yield put(setToastMessage(notifyPaylaod));
}

export function* updateToastMessage(action) {
	const payload = action.payload;
	yield put(setToastMessage(payload));
	yield delay(500);
	const notifyPaylaod = {
		message: '',
		type: payload?.type,
	};
	yield put(setToastMessage(notifyPaylaod));
}

export function* fetchSemanticByLabel(action) {
	const { label, initial = false } = action.payload;

	const REQUEST_URL = `/annotation-semantic/stamp/${label}`;

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.get, REQUEST_URL);
		const { data } = response;

		const { data: labelDetail } = data;
		const labelMapPayload = {
			[label]: labelDetail,
		};

		yield put(updateLabelMap(labelMapPayload));
		if (!initial) yield put(updateCurrentLabelDetail(labelDetail));
	} catch (error) {
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const { status } = error;
		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		if (!initial) {
			yield call(updateNotificationMessage, notifyPayload);
			yield call(updateErrorState, message);
		}
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* fetchTabSequenceById(action) {
	const { id, initial = false } = action.payload;

	const REQUEST_URL = `/tab-sequence/${id}`;

	yield call(updateLoadingState, true);
	try {
		const response = yield call(AxedApi.get, REQUEST_URL);

		const { data: tabSequence } = response;
		const _data = {
			id: id,
			name: tabSequence?.data?.name,
			annotationDropItems: tabSequence?.data?.annotationDropItems,
		};

		const tabSequenceMap = {
			[id]: _data,
		};

		yield put(updateTabSequenceMap(tabSequenceMap));
		yield put(updateCurrentTabSequence(_data));
	} catch (error) {
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const { status } = error;
		const notifyPayload = {
			message:
				status === 401
					? AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT]
					: message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		if (!initial) {
			yield call(updateNotificationMessage, notifyPayload);
			yield call(updateErrorState, message);
		}
	} finally {
		yield call(updateLoadingState, false);
	}
}

// Root Saga manages watcher lifecycle
export function* watcherSaga() {
	yield takeLatest(loadShared.type, fetchShared);
	yield takeEvery(fetchTabSequenceByIdAction.type, fetchTabSequenceById);
	yield takeEvery(updateNotificationMessageAction.type, updateToastMessage);
	yield takeEvery(fetchSemanticByLabelAction.type, fetchSemanticByLabel);
}
