import { Container, Typography, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import { UserFocusOrders } from 'components/FocusOrderComponents';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
			3,
		)} ${theme.spacing(0)}`,
	},
	cardContainer: {
		border: `1px solid ${theme.palette.border.grey}`,
		borderRadius: 8,
		margin: '25px 25px 25px 0',
	},
	cardParentContainer: {
		margin: '25px 0',
	},
}));

export function FocusOrderPage() {
	const classes = useStyles();
	const general = useTheme().general();

	return (
		<Container maxWidth={false} className={classes.root}>
			<Helmet>
				<title>Focus Order : Axe For Designers</title>
			</Helmet>
			<Grid container>
				<Grid item xs={12}>
					<Typography role="heading" aria-level="1" component="h1" variant="h4">
						Focus Order
					</Typography>
				</Grid>
			</Grid>
			<Grid
				justifyContent="space-between"
				className={classes.cardParentContainer}
				container
				spacing={3}
			>
				<UserFocusOrders className={general.my1} />
			</Grid>
		</Container>
	);
}
