import { Grid, Typography, useTheme, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';

import ReportName from './ReportName';
import ReportDelete from './ReportDelete';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTable } from 'common';
import { useFocusOrderScanSlice } from 'reducers/focus-order';
import {
	selectCurrentScanName,
	selectTotalScans,
	selectTotalPages,
	selectScans,
	selectScanLoading,
	selectCurrentPage,
} from 'reducers/focus-order/selector';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		border: `1px solid ${theme.palette.border.grey}`,
		borderRadius: 8,
		padding: 20,
	},
	deleteIcon: {
		'&:hover': {
			cursor: 'pointer',
			color: 'red',
		},
	},
	responsiveTable: {
		[theme.breakpoints.down('md')]: {
			maxWidth: 'calc(100vw - 6.5rem)',
			overflowX: 'scroll',
		},
	},
	reportLink: {
		color: `${theme.palette.border.grey}`,
	},
}));

const renderReportDate = (dateString) => {
	if (!dateString) {
		return '';
	}
	let date = new Date(dateString);
	return date.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true,
	});
};

const CONFIG = [
	{
		header: 'Report Name',
		accessor: 'selectedFrameName',
		Cell: (data) => data && <ReportName data={data} />,
		component: 'th',
		scope: 'row',
	},
	{
		header: 'Number of Tab Sequences',
		accessor: 'itemsCount',
		Cell: (data) =>
			data && (
				<Typography component="div" fontSize="14px">
					{data['itemsCount']}
				</Typography>
			),
	},
	{
		header: 'Date',
		accessor: 'createdAt',
		Cell: (data) =>
			data && (
				<Typography component="div" fontSize="14px">
					{renderReportDate(data['createdAt'])}
				</Typography>
			),
	},
	{
		header: 'Actions',
		Cell: (data) => data && <ReportDelete name={data?.name} id={data?.id} />,
	},
];

export const PER_PAGE = 6;
const VISITED_PAGES = new Set();

function UserFocusOrdersPure({ className }) {
	const classes = useStyles();
	const general = useTheme().general();

	const dispatch = useDispatch();
	const { fetchFocusOrderDetailAction } = useFocusOrderScanSlice();

	const totalItems = useSelector(selectTotalScans);
	const totalPages = useSelector(selectTotalPages);
	const scans = useSelector(selectScans);
	const loading = useSelector(selectScanLoading);
	const currentPage = useSelector(selectCurrentPage);

	const [currentTotalPages, setCurrentTotalPages] = useState(0);

	useEffect(() => {
		if (VISITED_PAGES.has(1)) return;
		dispatch(
			fetchFocusOrderDetailAction({
				perPage: PER_PAGE,
				page: 1,
			}),
		);
		VISITED_PAGES.add(1);
	}, []);

	useEffect(() => {
		if (currentTotalPages !== totalPages) {
			VISITED_PAGES.clear();
			setCurrentTotalPages(totalPages);
		}
	}, [totalPages]);

	const handlePageChange = (page) => {
		if (VISITED_PAGES.has(page)) return;

		VISITED_PAGES.add(page);

		dispatch(
			fetchFocusOrderDetailAction({
				perPage: PER_PAGE,
				page: page,
			}),
		);
	};

	return (
		<Grid
			item
			md={12}
			lg={12}
			className={clsx([classes.cardContainer, className])}
		>
			<Typography id="saved__reports" component="h2" variant="h5">
				Saved Reports
			</Typography>
			<Grid
				alignItems="center"
				justifyContent="center"
				container
				className={clsx([general.my1])}
			>
				<Grid item xs={12} className={clsx([classes.responsiveTable])}>
					<CustomTable
						loading={loading}
						onPageChange={handlePageChange}
						totalItems={totalItems}
						totalPages={currentTotalPages}
						config={CONFIG}
						aria-labelledby="saved__reports"
						currentPage={currentPage}
						data={scans}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

export const UserFocusOrders = memo(UserFocusOrdersPure);
