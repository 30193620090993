import { makeStyles } from '@mui/styles';
import { Box, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
	captionContainer: {
		maxWidth: 500,
	},
	caption: {
		fontSize: '1.1rem',
	},
	titlePrefix: {
		display: 'inline-flex',
		flexWrap: 'wrap',
		whiteSpace: 'inherit',
		justifyContent: 'center',
		paddingTop: 15,
	},
}));

export function AuthTitle({ title, titlePrefix, caption, className }) {
	const classes = useStyles();
	const general = useTheme().general();

	const renderCaptions = () => {
		if (Array.isArray(caption)) {
			return caption.map((item) => (
				<Box className={classes.captionContainer} key={item} my={1}>
					<Typography color="text.lighterGrey" className={classes.caption}>
						{item}
					</Typography>
				</Box>
			));
		}

		return (
			<Box className={classes.captionContainer} my={1}>
				<Typography color="text.lighterGrey" className={classes.caption}>
					{caption}
				</Typography>
			</Box>
		);
	};

	return (
		<Box className={clsx([classes.root, className])}>
			<Box>
				<Typography
					fontWeight="500"
					className={classes.titlePrefix}
					color="text.lightGrey"
					variant="h4"
					component="h1"
				>
					{titlePrefix}
					<Typography
						fontWeight="500"
						fontSize="2rem"
						color="text.primary"
						variant="h4"
						component="span"
						sx={{ ml: 1 }}
					>
						{title}
					</Typography>
				</Typography>
			</Box>
			{renderCaptions()}
		</Box>
	);
}
