import { DefaultLayout, AuthLayout, PolicyLayout } from 'layouts';
import { PageRouter } from 'common';
import {
	DashboardPage,
	AnnotationsPage,
	ViewAnnotationPage,
	ProductUsePage,
	ProductInfoPage,
	BlogsPage,
	LoginPage,
	PrivacyPolicyPage,
	RegisterPage,
	ResetPasswordPage,
	ForgotPasswordPage,
	GroupLabelPage,
	TermsOfUsePage,
	VerificationPage,
	ViewReportPage,
	NotFoundPage,
	SubscriptionsPage,
	TextNodeColorContrastPage,
	ColorContrastPage,
	TouchTargetPage,
	AutoAnnotationsPage,
	ManualAnnotationsPage,
} from 'pages';
import { FocusOrderPage } from 'pages/FocusOrder/FocusOrder';
import ViewFocusOrder from 'pages/ViewFocusOrder/ViewFocusOrder';

export const ROUTES = [
	{
		path: '/',
		element: <PageRouter />,
		children: [
			{
				element: <AuthLayout />,
				children: [
					{
						path: 'login',
						element: <LoginPage />,
					},
					{
						path: 'register',
						element: <RegisterPage />,
					},
					{
						path: 'verify',
						element: <VerificationPage />,
					},
					{
						path: 'password',
						children: [
							{
								path: 'forgot',
								element: <ForgotPasswordPage />,
							},
							{
								path: 'reset',
								element: <ResetPasswordPage />,
							},
						],
					},
				],
			},
			{
				element: <DefaultLayout />,
				children: [
					{
						index: true,
						path: 'a11y-check',
						element: <DashboardPage />,
					},
					{
						path: 'annotations',
						element: <AnnotationsPage />,
					},
					{
						path: 'annotation/:id',
						element: <ViewAnnotationPage />,
					},
					{
						path: 'focus-orders',
						element: <FocusOrderPage />,
					},
					{
						path: 'focus-order/:id',
						element: <ViewFocusOrder />,
					},
					{
						path: 'documentation',
						children: [
							{
								path: 'howtouse',
								element: <ProductUsePage />,
							},
							{
								path: 'productinfo',
								element: <ProductInfoPage />,
							},
						],
					},
					{
						path: 'use-cases',
						children: [
							{
								path: 'test-a-text-node-for-color-contrast-issue',
								element: <TextNodeColorContrastPage />,
							},
							{
								path: 'test-a-design-for-all-color-contrast-issues',
								element: <ColorContrastPage />,
							},
							{
								path: 'test-a-design-for-all-touch-target-issues',
								element: <TouchTargetPage />,
							},
							{
								path: 'pass-designer-intent-to-developer-for-form-controls-in-just-one-click',
								element: <AutoAnnotationsPage />,
							},
							{
								path: 'pass-designer-intent-to-developer',
								element: <ManualAnnotationsPage />,
							},
						],
					},
					{
						path: 'report/:scanId',
						element: <ViewReportPage />,
					},
					{
						path: 'beta_features',
						element: <SubscriptionsPage />,
					},
				],
			},
			{
				element: <PolicyLayout />,
				children: [
					{
						path: 'privacy_policy',
						element: <PrivacyPolicyPage />,
					},
					{
						path: 'terms',
						element: <TermsOfUsePage />,
					},
					{
						path: 'stamp/:label',
						element: <GroupLabelPage />,
					},
				],
			},
			{ path: '*', element: <NotFoundPage /> },
		],
	},
];
