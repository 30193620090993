import { draw } from 'patternomaly';
import {
	COLOR_CONTRAST_BG_COLOR,
	COLOR_CONTRAST_BORDER_COLOR,
	HEADINGS_BG_COLOR,
	HEADINGS_BORDER_COLOR,
	TEXT_SPACING_BG_COLOR,
	TEXT_SPACING_BORDER_COLOR,
	TOUCH_TARGET_BG_COLOR,
	TOUCH_TARGET_BORDER_COLOR,
} from 'constants';
import {
	CONTRAST_INDICATOR,
	HEADINGS_INDICATOR,
	TEXT_SPACING_INDICATOR,
	TOUCH_TARGET_INDICATOR,
} from 'assets/images/issue';

export function copyToClipboard(message) {
	function fallbackCopyToClipboard() {
		const textArea = document.createElement('textarea');
		textArea.value = message;

		// Avoid scrolling to bottom
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.position = 'fixed';

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand('copy');
		} catch (err) {
			console.error('Fallback: Oops, unable to copy', err);
		}

		document.body.removeChild(textArea);
	}

	if (!navigator?.clipboard?.writeText) {
		return fallbackCopyToClipboard();
	}

	navigator.clipboard
		.writeText(message)
		.catch((err) => console.log('err :>> ', err));
}

export function getIssueIndicator(issueTitle) {
	switch (issueTitle) {
		case 'Color Contrast':
			return CONTRAST_INDICATOR;
		case 'Headings':
			return HEADINGS_INDICATOR;
		case 'Text Spacing':
			return TEXT_SPACING_INDICATOR;
		default:
			return TOUCH_TARGET_INDICATOR;
	}
}

export function getBackgroundColor(issueTitle) {
	switch (issueTitle) {
		case 'Contrast Checker':
			return draw('triangle', COLOR_CONTRAST_BG_COLOR);
		case 'Headings':
			return draw('dot', HEADINGS_BG_COLOR);
		case 'Text Spacing':
			return draw('cross-dash', TEXT_SPACING_BG_COLOR);
		case 'Touch Target Size':
			return draw('zigzag', TOUCH_TARGET_BG_COLOR);
		default:
			return null;
	}
}

export function getBorderColor(issueTitle) {
	switch (issueTitle) {
		case 'Contrast Checker':
			return COLOR_CONTRAST_BORDER_COLOR;
		case 'Headings':
			return HEADINGS_BORDER_COLOR;
		case 'Text Spacing':
			return TEXT_SPACING_BORDER_COLOR;
		case 'Touch Target Size':
			return TOUCH_TARGET_BORDER_COLOR;
		default:
			return null;
	}
}

export const onPressEnter = (event, cb = () => {}) => {
	const { currentTarget, code } = event;
	if (code === 'Enter' || code === 'Space') {
		currentTarget?.click();
		setTimeout(() => {
			currentTarget?.focus();

			if (typeof cb === 'function') cb(event);
		}, 700);
	}
};

export const checkInputValidity = (element) => {
	if (!element || !(element instanceof HTMLElement)) {
		return {
			message: 'Invalid Element',
			isValid: true,
			type: null,
		};
	}

	const validityState = element.validity;

	const { name, title, value, dataset } = element;

	function hasOnlySpaces(value) {
		return value.trim().length === 0;
	}

	validityState['onlySpaces'] = hasOnlySpaces(value);

	const keys = [
		'badInput',
		'customError',
		'patternMismatch',
		'stepMismatch',
		'tooLong',
		'tooShort',
		'typeMismatch',
		'rangeOverflow',
		'rangeUnderflow',
		'valueMissing',
		'onlySpaces',
	];

	function toSentenceCase(s) {
		return s[0].toUpperCase() + s.slice(1);
	}

	function checkValidity(type) {
		switch (type) {
			case 'badInput':
			case 'onlySpaces':
			case 'customError':
				return `${toSentenceCase(dataset.label)} is not valid`;
			case 'patternMismatch':
			case 'stepMismatch':
			case 'tooLong':
			case 'tooShort':
				return title || name === 'email'
					? 'Email address is not valid'
					: `${name} is too short`;
			case 'typeMismatch':
				return title || name === 'email'
					? 'Email address is not valid'
					: `${name} is not matching the requested format`;
			case 'rangeOverflow':
			case 'rangeUnderflow':
				return `${name} is not within the range`;
			case 'valueMissing':
				return toSentenceCase(name) + ` is required`;
			default:
				return '';
		}
	}

	for (const key of keys) {
		const value = validityState[key];
		if (value) {
			return {
				message: checkValidity(key),
				isValid: value,
				type: key,
			};
		}
	}

	return {
		message: '',
		isValid: false,
		type: null,
	};
};

export function deleteFilters(filter) {
	if (
		filter?.issueStandard === 'ALL' ||
		filter?.issueStandard === 'WCAG 2.1 AA'
	) {
		delete filter?.issueStandard;
	}
	if (filter?.issueRule === 'ALL') {
		delete filter?.issueRule;
	}
	if (filter?.issueType === 'ALL') {
		delete filter?.issueType;
	}
	return filter;
}

export function capitalizeFirstLetter(filterName, str) {
	const filter =
		(str && filterName !== 'issueStandard') || str === 'ALL'
			? str?.toLowerCase()
			: str;
	return filter?.charAt(0)?.toUpperCase() + filter?.slice(1);
}

export function getDeviceType() {
	const userAgent = window.navigator.userAgent?.toLowerCase();

	if (userAgent.includes('mobile') || userAgent.includes('android')) {
		return 'Mobile';
	} else if (userAgent.includes('tablet') || userAgent.includes('ipad')) {
		return 'Tablet';
	} else {
		return 'Desktop';
	}
}
