import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';

export default function LeftScrollToBar(props) {
	const { sections, active, handleClick } = props;

	return (
		<Drawer
			variant="permanent"
			open={true}
			anchor="right"
			sx={{
				borderLeft: 0,
				'& .MuiDrawer-paper': {
					borderLeft: 0,
					marginTop: '100px',
					width: '400px',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
					component="nav"
				>
					<Typography gutterBottom component="h2" variant="h6">
						ON THIS PAGE
					</Typography>
					<Box component="ul" sx={{ listStyle: 'none', padding: 0 }}>
						{sections?.map((item) => (
							<Box mb={1} width={250} key={item?.title} component="li">
								<NavLink
									end
									onClick={() => handleClick(item)}
									to={`#${item?.elementId}`}
									aria-label={item?.title}
									aria-current={active ? 'page' : 'false'}
									style={{
										textDecoration: 'none',
										color: 'inherit',
										cursor: 'pointer',
										fontWeight: active === item?.elementId ? 'bold' : '',
										'&:hover, &:focus': {
											color: '#216EBE',
											boxShadow: 'none',
										},
									}}
								>
									{item?.title}
								</NavLink>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		</Drawer>
	);
}
