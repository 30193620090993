import axios from 'axios';
import _debounce from 'lodash/debounce';

import { AXED_API_CONFIG } from './Api.config';

class ApiWrapper {
	constructor(options) {
		const optHeaders = options || {};

		const headers = {
			'Content-Type': 'application/json',
			...optHeaders,
		};

		const defaultOptions = {
			headers,
			timeout: 30 * 1000,
			withCredentials: true,
		};

		const serviceOptions = { ...defaultOptions, ...options };
		this.service = axios.create(serviceOptions);
		this.#configInterceptor();
	}

	get = (url, config = {}) => {
		return this.service.get(url, config);
	};

	post = (url, payload, config = {}) => {
		return this.service.post(url, payload, config);
	};

	put = (url, payload, config = {}) => {
		return this.service.put(url, payload, config);
	};

	patch = (url, payload, config = {}) => {
		return this.service.patch(url, payload, config);
	};

	delete = (url, config = {}) => {
		return this.service.delete(url, config);
	};

	#configInterceptor = () => {
		this.service.interceptors.response.use(
			(data) => {
				return data;
			},
			(err) => {
				return Promise.reject(err.response);
			},
		);
	};
}

export const AxedApi = new ApiWrapper(AXED_API_CONFIG);
