import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import AccountMenu from './AccountMenu';
import { makeStyles } from '@mui/styles';
import { DEQUE_LOGO } from 'assets/images/common';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	logoContainer: {
		display: 'flex',
		marginRight: 2,
	},
	logo: {
		maxHeight: 54,
		cursor: 'pointer',
		verticalAlign: 'middle',
	},
	headerNoShadow: {
		boxShadow: 'none',
	},
}));

const ResponsiveAppBar = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate('/');
	};

	return (
		<AppBar
			position="fixed"
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			className={classes.headerNoShadow}
		>
			<Container maxWidth={false}>
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ display: { xs: 'none', md: 'flex' } }}
						className={classes.logoContainer}
					>
						<img
							onClick={handleLogoClick}
							className={classes.logo}
							src={DEQUE_LOGO}
							alt="Deque Logo"
						/>
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="menu"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={() => {}}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
					</Box>
					<Typography
						variant="h6"
						noWrap
						textAlign="center"
						component="div"
						sx={{ flexGrow: 1 }}
					>
						Axe For Designers
					</Typography>
					<Box sx={{ flexGrow: 0 }}>
						<AccountMenu />
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default ResponsiveAppBar;
