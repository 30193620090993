import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export default function ReportName({ data }) {
	const { name: name, id } = data;
	const useStyles = makeStyles((theme) => ({
		reportLink: {
			color: `${theme.palette.text.light}`,
		},
	}));
	const classes = useStyles();
	return (
		<Link to={`/focus-order/${id}`} className={clsx([classes.reportLink])}>
			<Typography component="span" variant="span">
				{name}
			</Typography>
		</Link>
	);
}
