import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthSlice } from 'reducers/auth';
import {
	selectIsSessionActive,
	selectAuthAction,
} from 'reducers/auth/selector';
import { useSharedSlice } from 'reducers/shared';
import {
	AUTH_ACTIONS_MESSAGES,
	AUTH_ACTIONS,
	TOAST_MESSAGE_TYPE,
} from 'constants';

import { extractPathFromRoutes } from './utils';

const REDIRECT_URL = 'redirect__url';

export function usePageRouterHandler(routes) {
	const { meAction } = useAuthSlice();
	const { setToastMessage } = useSharedSlice();

	const navigate = useNavigate();
	const { pathname, search, hash } = useLocation();
	const dispatch = useDispatch();
	const isSessionActive = useSelector(selectIsSessionActive);
	const authAction = useSelector(selectAuthAction);

	const { AUTH_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } = useMemo(() => {
		const AUTH_ROUTES = extractPathFromRoutes(routes[0]?.children[0].children);
		const PRIVATE_ROUTES = extractPathFromRoutes(
			routes[0]?.children[1].children,
		);
		const PUBLIC_ROUTES = extractPathFromRoutes(
			routes[0]?.children[2].children,
		);

		return { AUTH_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES };
	}, [routes]);

	const { isPrivateRoute, isAuthRoute, isPublicRoute } = useMemo(() => {
		const isPublicRoute = PUBLIC_ROUTES.some((paths) =>
			paths.every((path) => pathname.indexOf(path) > -1),
		);
		const isAuthRoute = AUTH_ROUTES.some((paths) =>
			paths.every((path) => pathname.indexOf(path) > -1),
		);
		const isPrivateRoute = PRIVATE_ROUTES.some((paths) =>
			paths.every((path) => pathname.indexOf(path) > -1),
		);

		return { isPrivateRoute, isPublicRoute, isAuthRoute };
	}, [pathname]);

	useEffect(() => {
		const currentURL = pathname + search + hash;
		if (isPublicRoute) return navigate(currentURL);

		if (isSessionActive === true) {
			const redirectUrl = sessionStorage.getItem(REDIRECT_URL);

			if (redirectUrl?.length) {
				sessionStorage.removeItem(REDIRECT_URL);
				return navigate(redirectUrl);
			}

			return isPrivateRoute
				? navigate(currentURL)
				: navigate('/a11y-check', { replace: true });
		}

		if (isAuthRoute) return navigate(currentURL);

		if (isSessionActive === false) {
			const payload = {
				message: AUTH_ACTIONS_MESSAGES[AUTH_ACTIONS.SESSION_TIMED_OUT],
				type: TOAST_MESSAGE_TYPE.ERROR,
			};

			if (authAction !== AUTH_ACTIONS.LOGOUT)
				dispatch(setToastMessage(payload));

			if (isPrivateRoute) sessionStorage.setItem(REDIRECT_URL, currentURL);

			return navigate('/login', { replace: true });
		} else if (pathname === '/') navigate('/login', { replace: true });
	}, [isSessionActive, isPrivateRoute, isAuthRoute, isPublicRoute]);

	useEffect(() => {
		// if (window) {
		// 	setTimeout(() => {
		// 		window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
		// 	}, 500);
		// }
		if (isAuthRoute || isPrivateRoute) dispatch(meAction());
	}, [pathname]);
}
