export const TOAST_MESSAGE_TYPE = {
	ERROR: 'error',
	SUCCESS: 'success',
	INFO: 'info',
	WARNING: 'warning',
};

export const SEMANTIC_GROUP_ICONS = {
	Landmarks: 'location_on',
	Headings: 'h_mobiledata',
	'Links & Buttons': 'link',
	Lists: 'list',
	Tables: 'table',
	'Color & Sensory': 'palette',
	Forms: 'rectangle',
	Images: 'imagesmode',
	Multimedia: 'videocam',
	'Content Visibility': 'visibility',
	'Content Readability': 'chrome_reader_mode',
	'Keyboard Interactivity': 'keyboard',
	'Dynamic Content': 'dynamic_feed',
	Listbox: 'list',
	Tabs: 'tab_unselected',
	Accordion: 'expand',
	'Dialog Modals': 'web_asset',
	Breadcrumb: 'dataset_linked',
	Carousel: 'view_carousel',
	Combobox: 'bento',
	default: 'web_asset',
	'Disclosure (Show/Hide)': 'toggle_on',
	Feed: 'feed',
	Grids: 'grid_view',
	'Menu/Menu bar': 'list',
	'Menu Button': 'menu',
	Meter: 'speed',
	Slider: 'tune',
	Spinbutton: '360',
	Switch: 'sync_alt',
	Toolbar: 'construction',
	Tooltip: 'speaker_notes',
	'Dialog/Modal': 'web_asset',
};

// FREEZE THE CONSTANT OBJECTS
const FREEZABLE_OBJECTS = [TOAST_MESSAGE_TYPE, SEMANTIC_GROUP_ICONS];

(function () {
	FREEZABLE_OBJECTS.forEach((item) => Object.freeze(item));
})();
