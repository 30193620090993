import { call, put, takeLatest } from 'redux-saga/effects';

import {
	forgotPasswordAction,
	loginAction,
	logoutAction,
	meAction,
	registerAction,
	resetPasswordAction,
	emailVerificationAction,
	resetState,
	setAuthAction,
	setError,
	setLoading,
	setResetFormField,
	updateIsSessionActive,
} from '.';
import { TOAST_MESSAGE_TYPE, AUTH_ACTIONS } from 'constants';
import { updateUserDetail } from 'reducers/user';
import { updateNotificationMessage } from 'reducers/shared/saga';
import { AxedApi } from 'services';
import { getDeviceType } from 'utils';

function* updateLoadingState(state = false) {
	yield put(setLoading(state));
}

function* updateErrorState(payload) {
	yield put(setError(payload));
}

function* updateAuthAction(payload) {
	yield put(setAuthAction(payload));
}

function* resetActiveFormFields(payload) {
	yield put(setResetFormField(payload));
}

export function* login(action) {
	const { payload } = action;
	const requestConfig = {
		headers: {
			'Device-Type': getDeviceType(),
		},
	};

	const REQUEST_URL = '/auth/signin';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(
			AxedApi.post,
			REQUEST_URL,
			payload,
			requestConfig,
		);
		const { data } = response;

		const { message, data: user } = data;

		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield put(updateUserDetail(user));
		yield call(me);
		yield call(resetActiveFormFields, true);
		yield call(updateAuthAction, AUTH_ACTIONS.LOGIN);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}
		const { message } = error.data;

		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* register(action) {
	const { payload } = action;

	const REQUEST_URL = '/auth/signup';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.post, REQUEST_URL, payload);
		const { data } = response;

		const { message, data: user } = data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateNotificationMessage, notifyPayload);

		yield put(updateUserDetail(user));
		yield call(resetActiveFormFields, true);
		yield call(updateAuthAction, AUTH_ACTIONS.REGISTER);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* me() {
	const REQUEST_URL = '/auth/me';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.get, REQUEST_URL);
		const { data } = response;

		yield put(updateIsSessionActive(data));
		yield call(resetActiveFormFields, true);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}

		const { message } = error.data;

		yield put(updateIsSessionActive(false));
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* logout() {
	const REQUEST_URL = '/auth/logout';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.delete, REQUEST_URL);
		const { data } = response;

		const { message } = data;

		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateAuthAction, AUTH_ACTIONS.LOGOUT);
		yield call(updateNotificationMessage, notifyPayload);
		yield put(resetState());
	} catch (error) {
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
	}
}

export function* forgotPassword(action) {
	const { payload } = action;

	const REQUEST_URL = '/auth/password/forgot';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.post, REQUEST_URL, payload);
		const { data } = response;

		const { message } = data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(resetActiveFormFields, true);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
		yield call(updateAuthAction, AUTH_ACTIONS.FORGOT_PASSWORD);
	}
}

export function* emailVerification(action) {
	const { payload } = action;

	const REQUEST_URL = '/auth/email/resend';

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.post, REQUEST_URL, payload);
		const { data } = response;

		const { message } = data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(resetActiveFormFields, true);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
		yield call(updateAuthAction, AUTH_ACTIONS.FORGOT_PASSWORD);
	}
}

export function* resetPassword(action) {
	const { payload } = action;

	const REQUEST_URL =
		'/auth/password/reset' +
		sessionStorage.getItem(process.env.REACT_APP_RESET_PASSWORD_PARAMS_PREFIX);

	yield call(updateLoadingState, true);

	try {
		const response = yield call(AxedApi.post, REQUEST_URL, payload);
		const { data } = response;

		const { message } = data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.SUCCESS,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(resetActiveFormFields, true);

		sessionStorage.removeItem(
			process.env.REACT_APP_RESET_PASSWORD_PARAMS_PREFIX,
		);
	} catch (error) {
		yield call(resetActiveFormFields, false);
		if (!error?.data) {
			return;
		}
		const { message } = error.data;
		const notifyPayload = {
			message,
			type: TOAST_MESSAGE_TYPE.ERROR,
		};

		yield call(updateNotificationMessage, notifyPayload);
		yield call(updateErrorState, message);
	} finally {
		yield call(updateLoadingState, false);
		yield call(updateAuthAction, AUTH_ACTIONS.RESET_PASSWORD);
	}
}

// Root Saga manages watcher lifecycle
export function* watcherSaga() {
	yield takeLatest(loginAction.type, login);
	yield takeLatest(logoutAction.type, logout);
	yield takeLatest(registerAction.type, register);
	yield takeLatest(meAction.type, me);
	yield takeLatest(forgotPasswordAction.type, forgotPassword);
	yield takeLatest(emailVerificationAction.type, emailVerification);
	yield takeLatest(resetPasswordAction.type, resetPassword);
}
