import {
	Pagination,
	Paper,
	TableRow,
	Box,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	TableHead,
	styled,
	alpha,
	Typography,
} from '@mui/material';
import styles from './index.module.scss';
import { memo, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';
import { LoadingIndicator } from 'common/LoadingIndicator';

const StyledTableRow = styled((props) => <TableRow {...props} />)(
	({ theme }) => ({}),
);

const StyledTableHead = styled((props) => <TableHead {...props} />)(
	({ theme }) => ({
		backgroundColor: theme.palette.table.headerBg,
		fontSize: 14,
	}),
);

const StyledTableCell = styled((props) => <TableCell {...props} />)(
	({ theme }) => ({
		borderBottom: `1px solid ${alpha(theme.palette.text.lighterGrey, 0.3)}`,
		fontSize: 14,
	}),
);

const StyledTableCellBold = styled((props) => <TableCell {...props} />)(
	({ theme }) => ({
		borderBottom: `1px solid ${alpha(theme.palette.text.lighterGrey, 0.3)}`,
		fontWeight: 'bold',
		fontSize: '1.15rem',
		color: `${theme.palette.text.primary}`,
	}),
);

export const CustomTable = memo(function CustomTablePure({
	perPage = 6,
	totalPages = 0,
	totalItems,
	config,
	loading,
	currentPage = 1,
	data = [],
	onPageChange = () => {},
	...props
}) {
	const [page, setPage] = useState(currentPage - 1);
	const [accessors, setAccessors] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [colProps, setColProps] = useState([]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage - 1);
		onPageChange(newPage);
	};

	useEffect(() => {
		if (Array.isArray(config)) {
			const [tableHeaders, tableAccessors, tableColumnProps] = config.reduce(
				(prev, curr) => {
					const { Cell, accessor, header, ...rest } = curr;
					const element = typeof Cell === 'function' ? Cell : accessor;

					prev[0].push(header);
					prev[1].push(element);
					prev[2].push(rest);

					return prev;
				},
				[[], [], []],
			);

			setHeaders(tableHeaders);
			setAccessors(tableAccessors);
			setColProps(tableColumnProps);
		}
	}, [config]);

	useEffect(() => {
		if (page + 1 !== currentPage) {
			setPage(currentPage - 1);
			onPageChange(currentPage);
		}
	}, [currentPage]);

	const rows = useMemo(() => {
		return data.slice(page * perPage, page * perPage + perPage);
	}, [page, data, data.length]);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * perPage - totalItems) : 0;

	const renderAlignProp = (index) => {
		if (headers[index]?.toLowerCase().includes('action')) {
			return 'center';
		} else {
			return 'inherit';
		}
	};

	const renderHeaders = () => {
		return headers.map((header, index) => (
			<StyledTableCellBold
				key={header}
				scope="col"
				role="columnheader"
				component="th"
				align={renderAlignProp(index)}
			>
				{header}
			</StyledTableCellBold>
		));
	};

	const renderTableColumns = (rowData) => {
		return accessors.map((accessor, index) => {
			let element;
			if (typeof accessor === 'function')
				element = accessor.call(null, rowData);
			else if (typeof accessor === 'string') element = _get(rowData, accessor);

			return (
				<StyledTableCell
					key={`table_col_${index}`}
					{...(colProps[index] || {})}
					align={renderAlignProp(index)}
				>
					{element}
				</StyledTableCell>
			);
		});
	};

	const renderTableRows = () => {
		return rows.map(
			(row, index) =>
				row && (
					<StyledTableRow key={row?.id || index}>
						{renderTableColumns(row)}
					</StyledTableRow>
				),
		);
	};

	const renderRowsInfo = () => {
		if (totalItems > 0) {
			return (
				page * perPage +
				1 +
				' to ' +
				Math.min(page * perPage + perPage, totalItems) +
				' out of ' +
				totalItems
			);
		} else {
			return 'No Data Available';
		}
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 500 }} {...props}>
				<StyledTableHead>
					<StyledTableRow>{renderHeaders()}</StyledTableRow>
				</StyledTableHead>
				<TableBody>
					{!loading && renderTableRows()}
					{emptyRows > 0 && (
						<StyledTableRow
							role="presentation"
							style={{ height: 63 * emptyRows }}
						>
							<TableCell colSpan={600} />
						</StyledTableRow>
					)}
					{loading && (
						<StyledTableRow style={{ height: 63 * perPage }}>
							<TableCell style={{ textAlign: 'center' }} colSpan={600}>
								<LoadingIndicator />
							</TableCell>
						</StyledTableRow>
					)}
				</TableBody>
			</Table>
			<Box p={2} className={clsx([styles['pagination_container']])}>
				{/* <Typography>{`${totalItem ? page * perPage + 1 : 0} to ${Math.min(
					page * perPage + perPage,
					totalItem,
				)} out of ${totalItem}`}</Typography> */}
				<Typography whiteSpace="nowrap">{renderRowsInfo()}</Typography>
				{totalPages > 1 && (
					<Pagination
						page={page + 1}
						onChange={handleChangePage}
						count={totalPages}
						size="large"
						showLastButton
						showFirstButton
						disabled={loading}
					/>
				)}
			</Box>
		</TableContainer>
	);
});
