import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state) => state.shared || initialState;

export const selectSuccessToastMessage = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.toastMessage?.success,
);
export const selectErrorToastMessage = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.toastMessage?.error,
);
export const selectInfoToastMessage = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.toastMessage?.info,
);
export const selectWarnToastMessage = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.toastMessage?.warn,
);

export const selectLabelMap = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.labelMap,
);

export const selectTabSequenceMap = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.tabSequenceMap,
);

export const selectCurrTabSequence = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.currentTabSequence || {},
);

export const selectLabelDetail = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.currentLabelDetail,
);

export const selectLabelDetailTitle = createDraftSafeSelector(
	[selectLabelDetail],
	(labelDetail) => labelDetail?.label,
);

export const selectLabelDetailDescription = createDraftSafeSelector(
	[selectLabelDetail],
	(labelDetail) => labelDetail?.description,
);

export const selectLabelDetailId = createDraftSafeSelector(
	[selectLabelDetail],
	(labelDetail) => labelDetail?.id,
);

export const selectLabelDetailType = createDraftSafeSelector(
	[selectLabelDetail],
	(labelDetail) => labelDetail?.type,
);

export const selectTemplate = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.shared,
);

export const selectLoading = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.loading,
);

export const selectError = createDraftSafeSelector(
	[selectDomain],
	(sharedState) => sharedState?.error,
);
