export let ISSUE_RULE;
(function (ISSUE_RULE) {
	ISSUE_RULE['CONTRAST CHECKER'] = 'CONTRAST CHECKER';
	ISSUE_RULE['HEADINGS'] = 'HEADINGS';
	ISSUE_RULE['TOUCH TARGET SIZE'] = 'TOUCH TARGET SIZE';
	ISSUE_RULE['TEXT SPACING'] = 'TEXT SPACING';
	ISSUE_RULE['GROUP LABEL'] = 'GROUP LABEL';
	ISSUE_RULE['VISIBLE LABELS'] = 'VISIBLE LABELS';
	ISSUE_RULE['LINE HEIGHT'] = 'LINE HEIGHT';
})(ISSUE_RULE || (ISSUE_RULE = {}));

export let ACCESSIBILITY_STANDARDS;
(function (ACCESSIBILITY_STANDARDS) {
	ACCESSIBILITY_STANDARDS['WCAG 2.0 AA'] = 'WCAG 2.0 AA';
	ACCESSIBILITY_STANDARDS['WCAG 2.1 AA'] = 'WCAG 2.1 AA';
})(ACCESSIBILITY_STANDARDS || (ACCESSIBILITY_STANDARDS = {}));

export let ISSUE_TYPE;
(function (ISSUE_TYPE) {
	ISSUE_TYPE['ISSUES'] = 'ISSUES';
	ISSUE_TYPE['BEST PRACTICES'] = 'BEST PRACTICES';
	ISSUE_TYPE['POTENTIAL ISSUES'] = 'POTENTIAL ISSUES';
})(ISSUE_TYPE || (ISSUE_TYPE = {}));

export const COLOR_CONTRAST_BG_COLOR = 'rgba(13, 37, 53, 1)';
export const LINE_HEIGHT_BG_COLOR = 'rgba(54, 162, 235, 1)';
export const HEADINGS_BG_COLOR = 'rgba(51, 117, 194, 1)';
export const TARGET_AREAS_BG_COLOR = 'rgba(75, 192, 192, 1)';
export const TOUCH_TARGET_BG_COLOR = 'rgba(255, 159, 64, 1)';
export const VISIBLE_LABELS_BG_COLOR = 'rgba(15, 202, 25, 1)';
export const TEXT_SPACING_BG_COLOR = 'rgba(244, 190, 55, 1)';

export const COLOR_CONTRAST_BORDER_COLOR = 'rgba(13, 37, 53, 1)';
export const LINE_HEIGHT_BORDER_COLOR = 'rgba(54, 162, 235, 1)';
export const HEADINGS_BORDER_COLOR = 'rgba(51, 117, 194, 1)';
export const TARGET_AREAS_BORDER_COLOR = 'rgba(75, 192, 192, 1)';
export const TOUCH_TARGET_BORDER_COLOR = 'rgba(255, 159, 64, 1)';
export const VISIBLE_LABELS_BORDER_COLOR = 'rgba(15, 202, 25, 1)';
export const TEXT_SPACING_BORDER_COLOR = 'rgba(244, 190, 55, 1)';

export const DEFAULT_PAGE_INCREMENT_THRESHOLD = 1;
export const DEFAULT_ISSUE_DETAIL_CURRENT_PAGE = 0;
export const DEFAULT_ISSUE_DETAIL_PER_PAGE = 10;
