import { createTheme } from '@mui/material';

import color from './color';
import general from './general';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
	color,
	general,
	palette,
	typography,
});

export default theme;
