import React, { useMemo } from 'react';
import { Icon, IconButton, Box, useTheme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useCallback } from 'react';
import { LoadingIndicator } from 'common';
import { useEffect } from 'react';
import { selectCurrScanImage } from 'reducers/focus-order/selector';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		border: `10px solid ${theme.palette.border.grey}`,
		padding: `20px 20px`,
		[theme.breakpoints.down('lg')]: {
			marginBottom: 10,
		},
	},
	imageProp: {
		width: '100%',
		height: '100%',
	},
	imageHolder: {
		border: `1px solid ${theme.palette.border.red}`,
	},
	icons: {
		alignItems: 'flex-end',
		position: 'absolute',
		right: '0',
		top: '0',
		zIndex: 5,
	},
	icon: {
		backgroundColor: `${theme.palette.text.black}`,
		color: `${theme.palette.white}`,
		cursor: 'pointer',
		margin: 1,
		padding: 4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 0,
		'&:first-child': {
			borderRadius: '5px 0px 0px 0px',
		},
		'&:last-child': {
			borderRadius: '0px 0px 0px 5px',
		},
		'&:hover': {
			backgroundColor: `${theme.palette.text.black}`,
		},
	},
	icon1: {},
	icon4: {},
}));

const resetTransformEvent = new Event('resetTransformation', { bubbles: true });

export function PreviewSection({ className }) {
	const classes = useStyles();
	const general = useTheme().general();

	const totalwrapper = React.useRef();

	const [isImageLoading, setImageLoading] = useState(true);
	const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

	useEffect(() => {
		const onFullscreenChange = () => {
			setIsFullScreenEnabled(Boolean(document.fullscreenElement));
			document.dispatchEvent(resetTransformEvent);
		};

		document.addEventListener('fullscreenchange', onFullscreenChange);

		return () =>
			document.removeEventListener('fullscreenchange', onFullscreenChange);
	}, [isFullScreenEnabled]);

	const toggleImageLoaded = useCallback((customState) => {
		setImageLoading((prev) => customState ?? !prev);
	}, []);

	const toggleFullScreen = useCallback((customState) => {
		setIsFullScreenEnabled((prev) => customState ?? !prev);
	}, []);

	function fullscreen(current) {
		const elem = current;
		toggleFullScreen();

		if (document.fullscreenElement) {
			return document.exitFullscreen();
		}

		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem.msRequestFullscreen();
		}
	}

	const frameImage = useSelector(selectCurrScanImage);

	useEffect(() => {
		if (!frameImage) toggleImageLoaded(true);
	}, [frameImage]);

	const addResetTransformation = (resetTransform) => {
		if (!document.hasOwnProperty('resetTransformation')) {
			document.addEventListener('resetTransformation', () => {
				resetTransform();
			});
		}
	};

	const RenderPreviewer = useMemo(() => {
		return ({ zoomIn, zoomOut, resetTransform, ...rest }) => (
			<Box>
				<Box
					role="group"
					className={clsx([general.flexDirCol, classes.icons])}
					aria-label="Preview action items"
				>
					<IconButton
						onClick={() => zoomIn()}
						className={clsx([classes.icon])}
						aria-label="zoom in"
					>
						<Icon>zoom_in</Icon>
					</IconButton>
					<IconButton
						onClick={() => zoomOut()}
						className={clsx([classes.icon])}
						aria-label="zoom out"
					>
						<Icon>zoom_out</Icon>
					</IconButton>
					<IconButton
						onClick={() => resetTransform()}
						className={clsx([classes.icon])}
						aria-label="fit to screen"
					>
						<Icon>fit_screen</Icon>
					</IconButton>
					<IconButton
						onClick={() => {
							fullscreen(totalwrapper.current);
							addResetTransformation(resetTransform);
							document.dispatchEvent(resetTransformEvent);
						}}
						className={clsx([classes.icon])}
						aria-label={!isFullScreenEnabled ? 'fullscreen' : 'exit fullscreen'}
					>
						<Icon>
							{!isFullScreenEnabled ? 'fullscreen' : 'fullscreen_exit'}
						</Icon>
					</IconButton>
				</Box>
				<TransformComponent
					wrapperStyle={{
						height: 'auto',
						width: '100%',
						maxHeight: isFullScreenEnabled ? '100vh' : '',
						background: `white`,
						backgroundImage: `radial-gradient(gray 1px, transparent 0)`,
						backgroundSize: `20px 20px`,
						backgroundPosition: `-19px -19px`,
					}}
					contentStyle={{
						width: '100%',
						height: isFullScreenEnabled ? '100vh' : '845px',
					}}
					className={clsx([classes.imageHolder])}
				>
					<img
						onLoad={() => toggleImageLoaded(false)}
						onError={() => toggleImageLoaded(true)}
						className={clsx([classes.imageProp])}
						src={frameImage}
						alt="Annotations frame"
					/>
					{isImageLoading && <LoadingIndicator />}
				</TransformComponent>
			</Box>
		);
	}, [frameImage, isImageLoading, isFullScreenEnabled]);

	return (
		<Grid
			position="relative"
			item
			xs={12}
			lg={7.8}
			className={clsx([classes.cardContainer])}
		>
			<Grid>
				<Box ref={totalwrapper} id="totalWrap">
					<TransformWrapper>{RenderPreviewer}</TransformWrapper>
				</Box>
			</Grid>
		</Grid>
	);
}
