export function createPatternFromPath(path) {
	if (typeof path !== 'string') throw new Error('Invalid Path');

	return path.split('/').filter((pathname) => pathname[0] !== ':');
}

export function extractPathFromRoutes(config, path = [], prefix = '') {
	if (Array.isArray(config)) {
		config.forEach((elem) => {
			const validPath = elem['path'];
			if (elem['children']) {
				path = path.concat(
					extractPathFromRoutes(elem['children'], [], prefix + validPath + '/'),
				);
			} else if (validPath) {
				path.push(createPatternFromPath(prefix + validPath));
			}
		});
	}

	return path;
}
