import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { watcherSaga } from './saga';

export const initialState = {
	loading: false,
	error: null,
	isSessionActive: null,
	resetFormField: false,
	authAction: '',
};

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateIsSessionActive(state, action) {
			state.isSessionActive = action.payload;
		},
		setError(state, action) {
			const { payload } = action;
			state.error = payload;
		},
		setLoading(state, action) {
			const { payload } = action;
			state.loading = payload;
		},
		setResetFormField(state, action) {
			const { payload = false } = action;
			state.resetFormField = payload;
		},
		setAuthAction(state, action) {
			const { payload } = action;
			state.authAction = payload;
		},
		resetState(state) {
			state.error = null;
			state.isSessionActive = false;
			state.resetFormField = false;
		},
		loginAction() {},
		logoutAction() {},
		registerAction() {},
		meAction() {},
		forgotPasswordAction() {},
		resetPasswordAction() {},
		emailVerificationAction() {},
	},
});

const { actions, reducer, name } = slice;

export const {
	setError,
	setLoading,
	setResetFormField,
	setAuthAction,
	loginAction,
	registerAction,
	meAction,
	updateIsSessionActive,
	logoutAction,
	resetState,
	forgotPasswordAction,
	resetPasswordAction,
	emailVerificationAction,
} = actions;

export const useAuthSlice = () => {
	useInjectReducer({ key: name, reducer });
	useInjectSaga({ key: name, saga: watcherSaga });
	return {
		...actions,
	};
};
